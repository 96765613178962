import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import deposits from "../../app/components/transaction/Deposits";
import css from "../css/Addcase.module.css";
import { Link } from "react-router-dom";
//import Rightcontainer from "../Components/Rightcontainer";
import "../css/Loader.css";
import Swal from "sweetalert2";
// import findGif from "/";
import Header from "../Components/Header";
import Googel from "../../assets/images/gpay.png";
import Paytm from "../../assets/images/paytm.png";
import Phonepe from "../../assets/images/phonepe.png";
import Upi from "../../assets/images/upi.png";
import Phonepe_small from "../../assets/images/phonepe-small.png";
import Bhimpe from "../../assets/images/bhim_logo.png";
import Amazonpe from "../../assets/images/amazonpay.png";
import { enable } from "workbox-navigation-preload";
// change 1:- imported useLocation
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const Addcase = ({ walletUpdate }) => {
  const history = useHistory();
  //chage 2:- useLocation() hook
  const location = useLocation();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();

  // const [globalState, setGlobal] = useState();
  const [next, setNext] = useState(1);
  const [process, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(false);
  const [isPhonePeActive, setPhonePeActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isUpiGatewayActive, setUpiGatewayActive] = useState(false);

  const [isManualPaymentActive, setManualPaymentActive] = useState(false);
  const [isManualUPIQR, setManualUPIQR] = useState(false);

  const [RazorPayKey, setRazorPayKey] = useState(false);
  const [RazorPayAccountName, setAccountName] = useState("Skill Based Gaming");

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();

  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState("");
  const [upiUtrNum, setupiUtrNum] = useState("");
  const [globalState, setGlobalState] = useState(0);

  const [isPaymentMethodChecked, setIsPaymentMethodChecked] = useState("");
  const [isQrCodeClicked, setIsQrCodeClicked] = useState(false);
  const [showManualPaymentPage, setManualPaymentPage] = useState(false);

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  const handleAmountChange = (e) => {
    let enteredValue = parseInt(e.target.value);
    setGlobalState(enteredValue);
  };

  const handlePayButtonClicks = () => {
    if (globalState >= 20 && isPaymentMethodChecked === "online") {
      method.current = "upipay"; // Assuming method is a ref
      // change 3:-

      if (paymentMethod === "phonepe") {
        phonePeDepositeUpi("link", "upi", "upipay", "Phonepe", phonePeStatusCB);
      } else if (paymentMethod === "upigateway") {
        depositUpiGateway("upigateway");
      }
      // setNext(2);
    } else {
      Swal.fire({
        icon: "error",
        title:
          "Please enter 20 or more then 20 add chip amount \n select payment mode",
      }).then(() => {
        return;
      });
    }
  };

  const ManualPayment = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const formData = new FormData();

    formData.append("Transaction_Screenshot", scrnshot);
    formData.append("amount", globalState);
    formData.append("referenceId", upiUtrNum);

    const response = await axios.post(
      baseUrl + `manual/deposit/txn`,
      formData,
      { headers }
    );
    console.log(response.data);
    if (response.data.status === "Pending") {
      setTimeout(() => {
        axios
          .get(baseUrl + `manual/depositstatus/${response.data._id}`, {
            headers,
          })
          .then((res) => {
            //console.log(res);
            const icon = res.data.status === "PAID" ? "success" : "danger";
            var title = "";
            if (res.data && res.data.status === "PAID") {
              title = "Deposit submitted successfully";
            } else if (res.data && res.data.status === "Pending") {
              title = "Deposit Transaction in process.";
            } else if (!res.data.status) {
              title = "Deposit Transaction Rejected Due to invalid UTR Number.";
            }

            history.push("/");
            setTimeout(() => {
              setProcess(false);
              Swal.fire({
                title: title,
                icon: icon,
                confirmButtonText: "OK",
              });
            }, 1000);
          });
      }, 30000);
      setProcess(true);
    } else {
      setProcess(false);
      Swal.fire({
        title: "Deposit Failed",
        icon: "danger",
        confirmButtonText: "OK",
      });
    }
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //function will get called when clicked on the pay button.
  async function displayRazorpayPaymentSdk(
    channel,
    method,
    upiMethod,
    razorpay
  ) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // creating a new order and sending order ID to backend
    const response = await axios.post(
      baseUrl + `user/razorpay_order`,
      {
        amount: globalState,
        channel: channel,
        payment_method: method,
        provider: walletOption,
        bankCode: bankCode,
        account_name: account_name,
        payment_gatway: razorpay,
      },
      { headers }
    );

    if (!response) {
      alert("Server error. please check are you online?");
      return;
    }

    //console.log(response.data.orderdata);
    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: RazorPayKey,
      name: RazorPayAccountName,
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        name: account_name,
        email: account_mail_id,
        contact: accountPhone,
      },
      handler: async (response) => {
        try {
          const paymentId = response.razorpay_payment_id;

          //const url = baseUrl+`rozarpay/capture/${paymentId}`;
          //const captureResponse = await axios.post(url, {},{headers})
          //console.log(captureResponse.data);
          checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, "FAILED");
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const checkrazorpaydeposit = (
    order_id,
    order_token,
    order_status,
    paymentId
  ) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaydesposit/response`,
        { order_id, order_token, order_status, paymentId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //use for decentrodepositeupi
  const decentroDepositeUpi = (channel, method, upiMethod, decentropay) => {
    //account_name
    //account_mail_id
    //alert(account_name);
    //if(account_name && account_mail_id){

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get("https://morniinc.in/payment.php?amount=" + globalState, {
        accountID: "AID_2309051230571814",
        secret_key: "KEY_9e88747cf61aede3bffe95122ded7372",
        payment_id: "2345678uyf",
        payment_purpose: "game",
        buyer_name: "pradeep",
        buyer_phone: "9236547898",
        buyer_email: "p@gmail.com",
        pay_amount: globalState,
      })
      .then((res) => {
        console.log(res);
        if (res.data.response.status === "Success") {
          let order_id = "res.data.txnID";
          let order_token = "res.data.data.decentroTxnId";
          //setProcess(false);
          window.open(res.data.response.pay_link);
          setTimeout(() => {
            //checkdecentrodeposit(order_id, order_token)
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
    // }else{
    //   Swal.fire({
    //     title: 'Account holder name or Mail id is required',
    //     icon: 'danger',
    //     confirmButtonText: "OK",
    // });
    // }
  };

  // change 4:- start
  const phonePeDepositeUpi = (
    channel,
    method,
    phonepay,
    peyMethod,
    phonePeCB
  ) => {
    if (globalState < 20) {
      Swal.fire({
        icon: "error",
        title: "Please enter 20 or more then 20 add chip amount",
      });
      return;
    }
    setProcess(true);
    const access_token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `phonepev1/accept?amount=${globalState}`,
        {
          amount: globalState,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
          account_name: account_name,
          payment_gatway: phonepay,
        },
        { headers: headers }
      )
      .then((res) => {
        console.log(res.data);

        if (res.data.success === true) {
          let tokenUrl = res.data.data.instrumentResponse.redirectInfo.url;
          if (
            window &&
            window.PhonePeCheckout &&
            window.PhonePeCheckout.transact
          ) {
            window.PhonePeCheckout.transact({
              tokenUrl: tokenUrl,
              callback: phonePeCB,
              type: "IFRAME",
            });
          }
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.msg,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  // change 4:- end

  // change 5:- start callbackfunction is added
  function phonePeStatusCB(response) {
    if (response === "USER_CANCEL") {
      Swal.fire({
        icon: "error",
        title: "Payment Failed!!!",
      }).then(() => {
        // window.location.assign("http://localhost:3000/addcase");
        window.location.assign(location.pathname);
      });
      return;
    } else if (response === "CONCLUDED") {
      Swal.fire({
        icon: "success",
        title: "Waw you done it ....",
      }).then(() => {
        window.location.assign(location.pathname);
        // window.location.assign("http://localhost:3000/");
      });
      return;
    }
  }
  //change 5 :- end

  const checkphonepedeposit = (order_id, order_token) => {
    console.log(order_token);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `phonepestatus/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "Deposit submited successfully"
            : "Transaction in failed";

        if (res.data.status == "Pending") {
          setTimeout(() => {
            checkphonepedeposit(order_id, order_token);
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 6000);
        } else {
          history.push("/");
          setTimeout(() => {
            Swal.fire({
              title: title,
              icon: icon,
              confirmButtonText: "OK",
            });
          }, 1000);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //Function to load pinelab script for the display of pinelab payment SDK.
  // function loadPinelabScript(src) {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // }

  //function will get called when clicked on the pay button.
  // async function displayPinelabFrame(channel, method, upiMethod, Phonepe) {
  //   const res2 = await loadPinelabScript(
  //     "https://checkout.pluralonline.com/v1/web-sdk-checkout.js"
  //   );

  //   if (!res2) {
  //     alert("Pinelab SDK failed to load. please check are you online?");
  //     return;
  //   }
  //   //setProcess(true);
  //   const access_token = localStorage.getItem("token")
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`
  //   }
  //   // creating a new order and sending order ID to backend
  //   const response = await axios.post(baseUrl + `user/pinelab_order`, { amount: globalState, channel: channel, payment_method: method, provider: walletOption, bankCode: bankCode, account_name: account_name, payment_gatway: pinelab }, { headers });

  //   if (!response) {
  //     alert("Server error. please check are you onlin?");
  //     return;
  //   }

  //   console.log(response.data);
  //   // Getting the order details back
  //   let order_id = response.data.txnID;
  //   let order_token = response.data.orderdata.token;
  //   const data = response.data.orderdata;

  //   const options = {
  //     theme: "default", // "default" or "black"
  //     orderToken: order_token,
  //     channelId: "WEB", // "APP" or "WEB"
  //     paymentMode: 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI',// comma separated - Example - 'CREDIT_DEBIT,NETBANKING,UPI,WALLET,EMI,DEBIT_EMI'
  //     countryCode: "91",// type = string, default "91"
  //     mobileNumber: accountPhone, // type = string, default = null
  //     emailId: account_mail_id, //default null
  //     showSavedCardsFeature: false, // type = boolean, default = true
  //     successHandler: async function (response) {
  //       console.log(response)
  //       // Handle success response
  //       const payment_id = response.payment_id
  //       const plural_order_id = response.plural_order_id
  //       checkpinelabdeposit(order_id, plural_order_id, 'SUCCESS', payment_id)
  //     },
  //     failedHandler: async function (response) {
  //       console.log(response)
  //       // Handle failure response
  //       //error_code: "401"
  //       //error_message: "Something went wrong"
  //       const payment_id = response.payment_id
  //       checkpinelabdeposit(order_id, order_token, 'FAILED', payment_id)
  //     }
  //   };
  //   const plural = new window.Plural(options);
  //   plural.open(options);
  // }

  // const checkpinelabdeposit = (order_id, order_token, order_status, paymentId) => {

  //   const access_token = localStorage.getItem("token")
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`
  //   }
  //   axios.post(baseUrl + `pinelabdesposit/response`, { order_id, order_token, order_status, paymentId }, { headers })
  //     .then((res) => {
  //       const icon = res.data.status === "PAID" ? "success" : "danger";
  //       const title = res.data.status === "PAID" ? "Deposit submited successfully" : "Transaction Failed";
  //       history.push('/');
  //       setTimeout(() => {
  //         Swal.fire({
  //           title: title,
  //           icon: icon,
  //           confirmButtonText: "OK",
  //         });
  //       }, 1000);

  //     })
  //     .catch((e) => {
  //       if (e.response.status === 401) {
  //         localStorage.removeItem('token');
  //         localStorage.removeItem('token');
  //         window.location.reload()
  //       }
  //     })
  // }

  //use for upigatway

  const depositUpiGateway = (method) => {
    console.log(
      globalState,
      method,
      account_name,
      account_mail_id,
      accountPhone
    );

    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/depositeupi`,
        {
          amount: globalState,
          payment_method: method,
          account_name: account_name,
          account_mail_id: account_mail_id,
          account_phone: accountPhone,
        },
        { headers }
      )
      .then((res) => {
        console.log("response of payment gateway:---", res);
        if (res.data.data.status) {
          let order_id = res.data.txnID;
          let order_token = res.data.data.data.order_id;
          // window.location.assign(res.data.data.data.payment_url);
          if (res?.data?.data?.data?.payment_url) {
            window.location.assign(res.data.data.data.payment_url);
          } else {
            window.location.assign(res.data.data.payment_url);
          }
          // setTimeout(() => {
          //   checkupideposit(order_id, order_token);
          //   setProcess(false);
          // }, 30000);
        } else {
          setProcess(false);

          Swal.fire({
            title: res.data.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        // alert(e);
        Swal.fire({
          title: e.message,
          icon: "danger",
          confirmButtonText: "OK",
        });
        // window.reload();
      });
  };

  const checkupideposit = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `depositupipay/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "PAID" ? "success" : "danger";
        const title =
          res.data.status === "PAID"
            ? "`Deposit submited successfully`"
            : "Transaction Failed";
        history.push("/landing");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          // localStorage.removeItem('token');
          // localStorage.removeItem('token');
          window.location.reload();
        }
      });
  };

  //use for cashfree gatway
  const deposit = (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/deposite`,
        {
          amount: globalState,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.payment_method === "app") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (res.data.data.payment_method === "netbanking") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (
          res.data.data.channel === "link" &&
          res.data.data.payment_method === "upi"
        ) {
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
          window.location.href = res.data.data.data.payload[upiMethod];
        } else if (
          res.data.data.channel === "qrcode" &&
          res.data.data.payment_method === "upi"
        ) {
          setQrCode(res.data.data.data.payload.qrcode);
          setProcess(false);
          setNext(3);
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const checkout = (paymentID) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `checkout/deposite/txn`,
        { txnID: paymentID },
        { headers }
      )
      .then((res) => {
        // alert(res.data.txnStatus)
        if (res.data.txnStatus === "PAID") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "success",
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        } else if (res.data.txnStatus === "FAILED") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "error",
            confirmButtonText: "OK",
          });
          setProcess(false);
          setNext(1);
        }
        // else if(res.data.txnStatus!="PENDING")
        // {
        //     clearInterval(checkInterval);
        //     Swal.fire({
        //       title: res.data.msg,
        //       icon: 'info',
        //       confirmButtonText: "OK",
        //   });
        // }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        console.log("Data is hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", res);
        setUserAllData(res.data);
        setAccount_mail_id(res.data.email);
        setAccount_name(res.data.holder_name || res.data.name);
        setAccountPhone(res.data.phone);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });

    axios
      .get(baseUrl + `gatewaysettings/data`)
      .then((res) => {
        //console.log(res);
        setCashFreeActive(res.data.isCashFreeActive);
        setRazorPayActive(res.data.isRazorPayActive);
        setDecentroActive(false);
        setPhonePeActive(res.data.Phonepestatus);
        setRazorPayKey(res.data.RazorPayKey);
        setAccountName(res.data.AccountName);
        setManualPaymentActive(res.data.isManualPaymentActive);
        setUpiGatewayActive(res.data.UpiGateway);
        setManualUPIQR(res.data.isManualUPIQR);
      })
      .catch((e) => {
        setCashFreeActive(false);
        setRazorPayActive(false);
        setDecentroActive(true);
        setPhonePeActive(false);
        setRazorPayKey(false);
        setManualPaymentActive(false);
        setManualUPIQR(false);
        setUpiGatewayActive(false);
        setAccountName("Skill Based Gaming");
      });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  //For get enabled payment method
  axios
    .get(baseUrl + "settings/data", {})
    .then((res) => {
      setPaymentMethod(res.data.isPaymentMethod);
      setManualUPI(res.data.manualUPI);
    })
    .catch((e) => {
      setPaymentMethod("");
    });

  const handleQrCodeClick = () => {
    setIsQrCodeClicked(true);
  };

  const handlePayButtonClick = () => {
    method.current = "upipay";
    displayRazorpayPaymentSdk("link", "upi", "upipay", "razorpay");
  };

  const [manualUPI, setManualUPI] = useState("");
  const [manualAmount, setManualAmount] = useState("");
  const [manualUTR, setManualUTR] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmitdata = async (e) => {
    e.preventDefault();

    if (manualUTR.length < 12 || manualUTR.length > 12) {
      Swal.fire({
        title: "UTR number must be 12 digit",
        icon: "error",
        confirmButtonText: "OK",
      }).then(() => {
        return;
      });
      setLoading(false);
      return;
    }

    setLoading(true);

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const formData = new FormData();

    formData.append("amount", manualAmount);
    formData.append("utr_number", manualUTR);

    const response = await axios.post(
      baseUrl + `temp/depositemanual`,
      formData,
      { headers }
    );
    console.log(response.data);
    if (response.data.status === "pending") {
      setProcess(false);
      Swal.fire({
        title: "Deposit Successfully submitted",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        setLoading(false);
        history.push("/");
      });
    } else {
      setProcess(false);
      Swal.fire({
        title: "Deposit Failed! " + response.data.message,
        icon: "danger",
        confirmButtonText: "OK",
      }).then(() => {
        setLoading(false);
      });
    }
  };

  function handelManualPaymentpage() {
    setManualAmount(globalState);
    setManualPaymentPage(true);
  }

  const [postsecond, setPostsecond] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "getmessageManualPayment").then((response) => {
      setPostsecond(response.data);
    });
  }, []);

  return (
    <>
      <Header user={userAllData} />
      <div
        className="leftContainer bg-white"
        style={{ minHeight: "100vh", height: "100%", paddingTop: "50px" }}
      >
        <div className="p-1">
          <div className="p-3">
            {/* new add case ui start */}
            {Boolean(!process) && (
              <div>
                {Boolean(next === 1) && (
                  <>
                    <div
                      className="d-flex justify-content-between wallet-back py-4"
                      style={{ alignItems: "center" }}
                    >
                      <Link to={"/profile"}>
                        <button
                          className="d-flex justify-content-between"
                          style={{
                            alignItems: "center",
                            backgroundColor: "#4782F4",
                            padding: "8px",
                            paddingInline: "20px",
                            border: "none",
                            borderRadius: "5px",
                            color: "#fff",
                            fontFamily: "Poppins",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            class="me-2"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                            ></path>
                          </svg>
                          <span
                            class="text-capitalize"
                            style={{ fontWeight: "bold" }}
                          >
                            Back
                          </span>
                        </button>
                      </Link>

                      <div
                        className={`${css.menu_items}`}
                        style={{ position: "relative" }}
                      >
                        <button
                          className="guide-btn d-flex "
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasBottom"
                          aria-controls="offcanvasBottom"
                          style={{ alignItems: "center" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            class="me-2"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                          </svg>
                          Guide
                        </button>
                      </div>
                    </div>

                    <div
                      className={`mb-3 ${
                        postsecond.length > 0
                          ? showManualPaymentPage
                            ? ""
                            : "d-none"
                          : "d-none"
                      }`}
                    >
                      <div
                        role="alert"
                        className="fade d-flex align-items-center justify-content-between alert alert-warning show"
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                          fontWeight: "800",
                        }}
                      >
                        {postsecond.length > 0 ? (
                          <p
                            className={`box-bg w-1001 ${
                              showManualPaymentPage ? "" : "d-none"
                            }`}
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "center",
                              fontWeight: "800",
                            }}
                          >
                            {postsecond.map((ele) => (
                              <p
                                className="message-text mb-0 "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                  textAlign: "unset",
                                  fontWeight: "800",
                                  color: "#664d03",
                                }}
                              >
                                {ele.message}
                              </p>
                            ))}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div
                      className={`mb-3 ${
                        showManualPaymentPage ? "d-none" : ""
                      }`}
                    >
                      <div
                        role="alert"
                        className="fade d-flex align-items-center justify-content-between alert alert-warning show"
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                          fontWeight: "800",
                        }}
                      >
                        <span>
                          <p
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "0.8rem",
                              textAlign: "unset",
                              fontWeight: "800",
                              color: "#664d03",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width={20}
                              height={20}
                              fill="red"
                            >
                              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>{" "}
                            &nbsp;&nbsp;यदि आप किसी भी पेमेंट का बैंक से
                            Complain डालते है तो आपके खाते को Block कर दिया
                            जायेगा ! इसलिए किसी और से अपने Ludo ID में पैसे न
                            डलवाये ! और यदि आप खुद जान भूझकर बैंक से रिफंड लेने
                            के लिए Complain डालते है तो आपकी Ludo ID पूर्णतः बंद
                            कर दी जाएगी !
                          </p>
                        </span>
                      </div>
                    </div>

                    <div
                      className="mb-3 card "
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        boxShadow: "0 .5rem 1rem rgba(0,0,0,.15)",
                      }}
                    >
                      {showManualPaymentPage ? (
                        <div className="">
                          <div
                            className="card"
                            style={{ border: "1px solid #ccc" }}
                          >
                            <div
                              className="card-header text-center"
                              style={{
                                fontWeight: "bold",
                                fontSize: "15px",
                                letterSpacing: "0.3px",
                                fontFamily: "Poppins",
                                background: "#076d83",
                                color: "#ffffff",
                                padding: "0.5rem 1.25rem",
                              }}
                            >
                              UPI Information
                            </div>
                            <div className="card-body">
                              <form onSubmit={handleSubmitdata}>
                                <div className="mb-3 d-flex flex-column align-items-start">
                                  <label
                                    className="form-label"
                                    for="formBasicName"
                                    style={{
                                      color: "#212529",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Copy UPI Information
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      disabled=""
                                      value={manualUPI}
                                      style={{
                                        background: "#e9ecef",
                                        border: "1px solid #e9ecef",
                                        padding: "1.2rem",
                                        height: "42px",
                                      }}
                                    />
                                    <button
                                      id="copyButton"
                                      onClick={() => {
                                        const button =
                                          document.getElementById("copyButton");

                                        navigator.clipboard.writeText(
                                          manualUPI
                                        );
                                        button.style.backgroundColor =
                                          "#2B7347";
                                        button.querySelector(
                                          "span"
                                        ).textContent = "Copied!!!";

                                        setTimeout(() => {
                                          button.style.backgroundColor =
                                            "#0d6efd";
                                          button.querySelector(
                                            "span"
                                          ).textContent = "Copy";
                                        }, 3000);
                                      }}
                                      className="btn  text-uppercase"
                                      style={{
                                        backgroundColor: "rgb(5, 89, 107)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontFamily: "Poppins",
                                          color: "#fff",
                                          fontSize: "15px",
                                        }}
                                      >
                                        copy
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="mb-3 d-flex flex-column align-items-start">
                                  <label
                                    className="form-label"
                                    for="formBasicAadhar"
                                    style={{
                                      color: "#212529",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Enter Amount
                                  </label>
                                  <input
                                    placeholder="Enter Amount"
                                    type="text"
                                    maxLength={12}
                                    name="manualAmount"
                                    className="form-control"
                                    style={{
                                      border: "1px solid #ced4da",
                                      borderRadius: "5px",
                                    }}
                                    value={manualAmount}
                                    required
                                    min={10}
                                    max={100000}
                                    onChange={(e) => {
                                      const enteredValue =
                                        parseInt(e.target.value) || 0;
                                      setManualAmount(
                                        enteredValue > 0
                                          ? enteredValue <= 100000
                                            ? enteredValue
                                            : 100000
                                          : enteredValue < 0
                                          ? 10
                                          : 0
                                      );
                                    }}
                                  />
                                </div>
                                <div className="mb-3 d-flex flex-column align-items-start">
                                  <label
                                    className="form-label"
                                    for="formBasicEmail"
                                    style={{
                                      color: "#212529",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    Enter UTR Number
                                  </label>
                                  <input
                                    style={{
                                      border: "1px solid #ced4da",
                                      borderRadius: "5px",
                                    }}
                                    placeholder="Enter UTR Number"
                                    type="text"
                                    min={12}
                                    name={manualUTR}
                                    id="manualUTR"
                                    className="form-control"
                                    value={manualUTR}
                                    onChange={(e) =>
                                      setManualUTR(e.target.value)
                                    }
                                    required
                                  />
                                </div>

                                <p
                                  className=""
                                  style={{
                                    fontSize: "11px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  Please enter the REF NO/Reference NO/UTR
                                  (12-digit number) of your transfer and we will
                                  finish your recharge as soon as possible.
                                </p>
                                <div className="d-grid w-100">
                                  <button
                                    className={`btn pay-addcash-btn mb-2 ${
                                      loading ? "disabled" : ""
                                    }`}
                                    style={{
                                      color: "white",
                                      fontFamily: "Poppins",
                                    }}
                                  >
                                    {loading
                                      ? "Loading..."
                                      : "Pay " + manualAmount}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div
                        className={`card-body ${
                          showManualPaymentPage ? "d-none" : ""
                        }`}
                      >
                        <div className="form-group">
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <label
                              htmlFor="amount"
                              className="form-label w-100 text-start"
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                              }}
                            >
                              Enter Amount
                            </label>

                            <div className="input-group mb-4 mt-2">
                              <span
                                className="input-group-text icon   text-dark "
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                }}
                              >
                                ₹
                              </span>
                              <input
                                type="tel"
                                id="amountInput"
                                placeholder="Amount"
                                min={20}
                                max={10000}
                                className="form-control add-cash-input"
                                defaultValue=""
                                onChange={handleAmountChange}
                              />
                            </div>
                            <div
                              id="whichPG"
                              className="d-flex justify-content-center align-items-center mb-2 w-100"
                            >
                              {paymentMethod == "manual" ? (
                                <>
                                  {/* -------START----------- */}
                                  <div
                                    className="form-check form-check-inline d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <input
                                      type="radio"
                                      name="whichPG"
                                      id="manual"
                                      className="form-check-input"
                                      defaultValue="manual"
                                      defaultChecked=""
                                      onChange={() =>
                                        setIsPaymentMethodChecked("manual")
                                      }
                                    />
                                    <label
                                      htmlFor="phonepe"
                                      className="form-check-label"
                                    >
                                      <h5 className="text-capitalize mb-0 upi-radio">
                                        Manual
                                      </h5>
                                    </label>
                                  </div>
                                  {/* -------END----------- */}
                                </>
                              ) : paymentMethod === "both" ? (
                                <>
                                  {/* Content for other payment methods */}
                                  {/* First content */}
                                  <div
                                    className="form-check form-check-inline d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <input
                                      type="radio"
                                      name="whichPG"
                                      id="phonepe"
                                      className="form-check-input"
                                      defaultValue="phonepe"
                                      defaultChecked=""
                                      onChange={() =>
                                        setIsPaymentMethodChecked("online")
                                      }
                                    />
                                    <label
                                      htmlFor="phonepe"
                                      className="form-check-label"
                                    >
                                      <h5 className="text-capitalize mb-0 upi-radio ">
                                        Online
                                      </h5>
                                    </label>
                                  </div>

                                  {/* Second content */}
                                  <div
                                    className="form-check form-check-inline d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <input
                                      type="radio"
                                      name="whichPG"
                                      id="manual"
                                      className="form-check-input"
                                      defaultValue="manual"
                                      defaultChecked=""
                                      onChange={() =>
                                        setIsPaymentMethodChecked("manual")
                                      }
                                    />
                                    <label
                                      htmlFor="phonepe"
                                      className="form-check-label"
                                    >
                                      <h5 className="text-capitalize mb-0 upi-radio">
                                        Manual
                                      </h5>
                                    </label>
                                  </div>
                                </>
                              ) : (
                                <div
                                  className="form-check form-check-inline d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <input
                                    type="radio"
                                    name="whichPG"
                                    id="phonepe"
                                    className="form-check-input"
                                    defaultValue="phonepe"
                                    defaultChecked=""
                                    onChange={() =>
                                      setIsPaymentMethodChecked("online")
                                    }
                                  />
                                  <label
                                    htmlFor="phonepe"
                                    className="form-check-label"
                                  >
                                    <h5 className="text-capitalize mb-0 upi-radio ">
                                      Online
                                    </h5>
                                  </label>
                                </div>
                              )}
                            </div>

                            <div className="d-grid w-100">
                              <button
                                className="btn pay-addcash-btn"
                                style={{
                                  color: "white",
                                  fontFamily: "Poppins",
                                }}
                                // onClick={handlePayButtonClicks}
                                onClick={
                                  isPaymentMethodChecked === "manual"
                                    ? () => handelManualPaymentpage(true)
                                    : handlePayButtonClicks
                                }
                              >
                                {isPaymentMethodChecked === "manual"
                                  ? "Process to payment"
                                  : "Pay"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="text-center">
                        <p className="payments-secured ">Payments Secured By</p>
                      </div>
                    </div>
                    <div className="d-flex gap-3 justify-content-center">
                      <img width="48" src={Googel} alt="" />
                      <img width="48" src={Paytm} alt="" />
                      <img width="48" src={Phonepe} alt="" />
                      <img width="48" src={Upi} alt="" />
                    </div>
                  </>
                )}

                {Boolean(next === 2) && (
                  <>
                    {/* <div
                      onClick={() => {
                        method.current = "upipay";
                        displayRazorpayPaymentSdk(
                          "link",
                          "upi",
                          "upipay",
                          "razorpay"
                        );
                      }}
                      className="add-fund-box mt-3"
                      style={{ paddingTop: "0px", height: "60px" }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          backgroundColor: "#fafafa",
                          border: "1px solid #e0e0e0",
                          borderRadius: "7px",
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            height: "60px",
                            display: "flex",
                            textAlign: "center",
                          }}
                        >
                          <img
                            width="40px"
                            src="UPI.png"
                            alt=""
                            style={{
                              marginLeft: "7px",
                              paddingBottom: "10px",
                              paddingLeft: "3px",
                              paddingTop: "5px",
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-center flex-column ml-4">
                          <div className="jss30">
                            <strong>Deposit Here</strong>
                          </div>
                          <div className="jss31"></div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-12 p-3">
                      <div className="p-3">
                        <div>
                          <div
                            style={{
                              paddingLeft: "2rem",
                              paddingRight: "1.5rem",
                              paddingBottom: "4rem",
                            }}
                          >
                            <div className="pt-1 pb-1">
                              <div
                                className="d-flex pt-1 pb-1 align-items-center"
                                style={{ cursor: "pointer" }}
                              >
                                <div
                                  className="upi_icon text-center"
                                  style={{
                                    height: "32px",
                                    width: "32px",
                                    margin: "0.4rem 1rem 0px 0px",
                                    borderRadius: "4px",
                                    backgroundColor: "#E6EBEE",
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="22"
                                    viewBox="0 0 16 22"
                                    fill="rgba(17, 56, 91, 1)"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M4.12508 19.5434L7.19063 2.67879C7.25358 2.33249 7.45495 2.0268 7.74828 1.83226C8.34355 1.43747 9.14615 1.59999 9.54094 2.19526L15.6104 11.3468C15.9733 11.894 15.8686 12.6263 15.367 13.0499L6.23194 20.7629C5.93695 21.012 5.54611 21.1163 5.16626 21.0472C4.46349 20.9195 3.99733 20.2462 4.12508 19.5434ZM8.22484 2.55081C8.12706 2.61566 8.05994 2.71756 8.03895 2.83299L4.9734 19.6976C4.93082 19.9319 5.08621 20.1563 5.32046 20.1989C5.44708 20.2219 5.57736 20.1872 5.67569 20.1041L14.8107 12.3911C14.9779 12.2499 15.0128 12.0058 14.8919 11.8234L8.82239 2.67181C8.69079 2.47339 8.42326 2.41922 8.22484 2.55081Z"
                                    ></path>
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M5.17548 1.81423C4.7897 1.26156 3.92905 1.44517 3.8024 2.10717L0.388789 19.9497C0.25566 20.6456 1.06126 21.1305 1.61388 20.6872L11.7861 12.5269C12.0989 12.2759 12.1637 11.8254 11.9341 11.4965L5.17548 1.81423Z"
                                    ></path>
                                  </svg>
                                </div>
                                <div>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontWeight: "600",
                                      color: "#11385b",
                                      fontSize: "18px",
                                    }}
                                  >
                                    UPI
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                className="qr-code"
                                onClick={handleQrCodeClick}
                              >
                                <div className="d-inline-block">
                                  <div className="qr-image-add">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      viewBox="0 0 16 16"
                                      fill="rgba(17, 56, 91, 1)"
                                    >
                                      <path d="M2 2H4V4H2V2Z"></path>
                                      <path d="M6 0V6H0V0H6ZM5 1H1V5H5V1ZM4 12H2V14H4V12Z"></path>
                                      <path d="M6 10V16H0V10H6ZM1 11V15H5V11H1ZM12 2H14V4H12V2Z"></path>
                                      <path d="M10 0V6H16V0H10ZM15 1V5H11V1H15ZM8 1V0H9V2H8V4H7V1H8ZM8 6V4H9V6H8ZM6 8V7H7V6H8V8H9V7H14V8H10V9H7V8H6ZM6 8V9H2V8H1V9H0V7H3V8H6ZM16 9H15V7H16V9ZM15 9H14V11H16V10H15V9ZM11 9H13V10H12V11H11V9ZM13 12V11H12V12H11V13H9V14H12V12H13ZM13 12H16V13H14V14H13V12ZM9 11V12H10V10H7V11H9Z"></path>
                                      <path d="M7 12H8V15H12V16H7V12ZM16 14V16H13V15H15V14H16Z"></path>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <span className="qr-code-text">Qr Code </span>
                                </div>
                              </div>
                              <div className="mt-2 text-center">
                                <img
                                  height="58px"
                                  className=" mr-0"
                                  src={Googel}
                                  alt=""
                                />
                                <img
                                  height="20px"
                                  className=" mr-2"
                                  src={Paytm}
                                  alt=""
                                />
                                <img
                                  height="25px"
                                  className=" mr-2"
                                  src={Phonepe_small}
                                  alt=""
                                />
                                <img
                                  height="20px"
                                  className=" mr-2"
                                  src={Bhimpe}
                                  alt=""
                                />
                                <img
                                  height="20px"
                                  className=" mr-2"
                                  src={Amazonpe}
                                  alt=""
                                />
                              </div>
                              <div className="mt-3 qr-cheakbox">
                                <input
                                  className="mr-3"
                                  type="checkbox"
                                  name="one-click-enabled"
                                />
                                Save Mode for quick checkout
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          id=""
                          className={`pay-btn-addcase ${
                            !isQrCodeClicked ? "disabled" : ""
                          }`}
                          onClick={handlePayButtonClick}
                          disabled={!isQrCodeClicked}
                        >
                          <span>Pay</span>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {/* new add case ui end */}
        {Boolean(next === 3) && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <img src={qrCode} alt="img" />
          </div>
        )}
        {Boolean(next === 4) && (
          <div className="px-4 py-3">
            <div className="pb-3">
              <div className={`${css.games_section}`}>
                <div className="d-flex position-relative align-items-center justify-content-between">
                  <div
                    className={`${css.games_section_title}`}
                    style={{ fontSize: "1.1em" }}
                  >
                    Amount to be added ₹<span>{globalState}</span>
                  </div>
                  <button
                    type="button"
                    onClick={() => setNext(1)}
                    className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "rgb(241, 241, 241)",
                width: "100%",
                height: "10px",
                position: "absolute",
                left: "0",
                right: "0",
              }}
            ></div>
            <div className="d-flex flex-column mt-4">
              <div className="games-section-title">Pay Through</div>
              <div
                className="add-fund-box mt-3"
                style={{ paddingTop: "0px", height: "60px" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #e0e0e0",
                    borderRadius: "7px",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "60px" }}
                  >
                    <img
                      width="45px"
                      src="all-wallets.png"
                      alt=""
                      style={{
                        marginLeft: "7px",
                        paddingLeft: "3px",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center flex-column ml-4">
                    <div className="jss30">
                      <strong>Other Wallets</strong>
                    </div>
                    <div className="jss31"></div>
                  </div>
                  <div className="d-flex justify-content-center ml-auto mr-3">
                    <button
                      type="button"
                      onClick={() => setNext(2)}
                      className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <select
              className="form-control mt-4"
              style={{
                border: "1px solid rgb(224, 224, 224)",
                outline: "none",
                borderRadius: "5px",
                background: "rgb(250, 250, 250)",
                height: "3rem",
              }}
              onChange={(e) => {
                setWalletOption(e.target.value);
              }}
            >
              <option value="airtel">Airtel Money</option>
              <option value="freecharge">Freecharge</option>
              <option value="mobikwik">Mobikwik</option>
              <option value="ola">Ola Money</option>
              <option value="jio">Reliance Jio Money</option>
            </select>
            <div className={`${css.refer_footer}`}>
              <div className="d-grid gap-2 col-12 mx-auto">
                <button
                  type="button"
                  className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                  onClick={() => {
                    deposit("link", "app");
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
        {Boolean(next === 5) && (
          <div className="px-4 py-3">
            <div className="pb-3">
              <div className={`${css.games_section}`}>
                <div className="d-flex position-relative align-items-center justify-content-between">
                  <div
                    className={`${css.games_section_title}`}
                    style={{ fontSize: "1.1em" }}
                  >
                    Amount to be added ₹<span>{globalState}</span>
                  </div>
                  <button
                    type="button"
                    onClick={() => setNext(1)}
                    className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                background: "rgb(241, 241, 241)",
                width: "100%",
                height: "10px",
                position: "absolute",
                left: "0",
                right: "0",
              }}
            ></div>
            <div className="d-flex flex-column mt-4">
              <div className="games-section-title">Pay Through</div>
              <div
                className="add-fund-box mt-3"
                style={{ paddingTop: "0px", height: "60px" }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: "#fafafa",
                    border: "1px solid #e0e0e0",
                    borderRadius: "7px",
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "60px" }}
                  >
                    <img
                      width="45px"
                      src="/bank1.png"
                      alt=""
                      style={{
                        marginLeft: "7px",
                        paddingLeft: "3px",
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center flex-column ml-4">
                    <div className="jss30">
                      <strong>Net Banking</strong>
                    </div>
                    <div className="jss31"></div>
                  </div>
                  <div className="d-flex justify-content-center ml-auto mr-3">
                    <button
                      type="button"
                      onClick={() => setNext(2)}
                      className="btn btn-info text-white font-weight-bold text-uppercase px-2 py-1"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <select
              className="form-control mt-4"
              style={{
                border: "1px solid rgb(224, 224, 224)",
                outline: "none",
                borderRadius: "5px",
                background: "rgb(250, 250, 250)",
                height: "3rem",
              }}
              onChange={(e) => {
                setBankCode(e.target.value);
              }}
            >
              <option value="3003">Axis Bank</option>
              <option value="3005">Bank of Baroda - Retail Banking</option>
              <option value="3006">Bank of India</option>
              <option value="3007">Bank of Maharashtra</option>
              <option value="3009">Canara Bank</option>
              <option value="3010">Catholic Syrian Bank</option>
              <option value="3011">Central Bank of India</option>
              <option value="3012">City Union Bank</option>
              <option value="3016">Deutsche Bank</option>
              <option value="3017">DBS Bank Ltd</option>
              <option value="3018">DCB Bank - Personal</option>
              <option value="3019">Dhanlakshmi Bank</option>
              <option value="3020">Federal Bank</option>
              <option value="3021">HDFC Bank</option>
              <option value="3022">ICICI Bank</option>
              <option value="3023">IDBI Bank</option>
              <option value="3024">IDFC FIRST Bank</option>
              <option value="3026">Indian Bank</option>
              <option value="3027">Indian Overseas Bank</option>
              <option value="3028">IndusInd Bank</option>
              <option value="3029">Jammu and Kashmir Bank</option>
              <option value="3030">Karnataka Bank Ltd</option>
              <option value="3031">Karur Vysya Bank</option>
              <option value="3032">Kotak Mahindra Bank</option>
              <option value="3033">
                Laxmi Vilas Bank - Retail Net Banking
              </option>
              <option value="3037">Punjab & Sind Bank</option>
              <option value="3038">
                Punjab National Bank - Retail Net Banking
              </option>
              <option value="3039">RBL Bank</option>
              <option value="3040">Saraswat Bank</option>
              <option value="3042">South Indian Bank</option>
              <option value="3043">Standard Chartered Bank</option>
              <option value="3044">State Bank Of India</option>
              <option value="3052">Tamilnad Mercantile Bank Ltd</option>
              <option value="3054">UCO Bank</option>
              <option value="3055">Union Bank of India</option>
              <option value="3058">Yes Bank Ltd</option>
              <option value="3060">Bank of Baroda - Corporate</option>
              <option value="3061">Bank of India - Corporate</option>
              <option value="3062">DCB Bank - Corporate</option>
              <option value="3064">Lakshmi Vilas Bank - Corporate</option>
              <option value="3065">Punjab National Bank - Corporate</option>
              <option value="3066">State Bank of India - Corporate</option>
              <option value="3067">Union Bank of India - Corporate</option>
              <option value="3071">Axis Bank Corporate</option>
              <option value="3072">Dhanlaxmi Bank Corporate</option>
              <option value="3073">ICICI Corporate Netbanking</option>
              <option value="3074">Ratnakar Corporate Banking</option>
              <option value="3075">Shamrao Vithal Bank Corporate</option>
              <option value="3076">Equitas Small Finance Bank</option>
              <option value="3077">Yes Bank Corporate</option>
              <option value="3079">Bandhan Bank- Corporate banking</option>
              <option value="3080">
                Barclays Corporate- Corporate Banking - Corporate
              </option>
              <option value="3081">Indian Overseas Bank Corporate</option>
              <option value="3083">City Union Bank of Corporate</option>
              <option value="3084">HDFC Corporate</option>
              <option value="3086">Shivalik Bank</option>
              <option value="3087">AU Small Finance</option>
              <option value="3088">Bandhan Bank - Retail Net Banking</option>
              <option value="3041">Shamrao Vitthal Co-operative Bank</option>
              <option value="3051">Tamil Nadu State Co-operative Bank</option>
              <option value="3089">Utkarsh Small Finance Bank</option>
              <option value="3090">
                The Surat People’s Co-operative Bank Limited
              </option>
              <option value="3091">
                Gujarat State Co-operative Bank Limited
              </option>
              <option value="3092">HSBC Retail Netbanking</option>
              <option value="3102">Jana Small Finance Bank</option>
            </select>
            <div className={`${css.refer_footer}`}>
              <div className="d-grid gap-2 col-12 mx-auto">
                <button
                  type="button"
                  className={`${css.block} bg-primary rounded text-white font-weight-bold text-uppercase`}
                  onClick={() => {
                    deposit("link", "netbanking");
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
        {Boolean(process) && (
          <div className="loaderReturn" style={{ zIndex: "99" }}>
            <img
              src={"https://ludo57.in/Images/LandingPage_img/loader1.gif"}
              style={{ width: "100%" }}
              alt="img"
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Addcase;
