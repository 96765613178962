import React, { useEffect, useState, useRef } from "react";
import "../css/viewGame1.css";
import "../css/layout.css";
import axios from "axios";
import { useHistory, useLocation, Link, NavLink } from "react-router-dom";
import Header from "../Components/Header";
import css from "../css/Pan.module.css";
//import Rightcontainer from '../Components/Rightcontainer';
import Swal from "sweetalert2";
import "../css/Loader.css";
import file_icon from "../../assets/images/file-icon.png";
import global_cross from "../../assets/images/global-cross.png";
import userimage from "../Avtar/user.png";
import creatorAudio from "./creator_audio.mp3";
import acceptorAudio from "./acceptor_audio.mp3";

export default function ViewGame1(props) {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  var websocketURL = process.env.REACT_APP_SOCKET_API;

  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [Game, setGame] = useState();
  const [status, setStatus] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot, setScrnshot] = useState(null);
  const [scrnshot1, setScrnshot1] = useState(""); // ADDED BY TEAM

  const [reason, setReason] = useState(null);
  const [socket, setSocket] = useState();
  const [roomcode, setRoomcode] = useState("");
  let submitReq = useRef(false);
  const isMounted = useRef(true);

  const [submitProcess, setProcess] = useState(true);
  const [loading, setLoading] = useState(false);

  const getPost = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setLoading(true);
    await axios
      .patch(
        baseUrl + `challange/roomcode/${path}`,
        {
          roomCode: roomcode,
        },
        { headers }
      )
      .then((res) => {
        setLoading(false);
        if (res.data === "Room code dose not match for ludo classic game") {
          Swal.fire({
            position: "center",
            icon: "error",
            type: "error",
            title: res.data,
            showConfirmButton: true,
          }).then(() => {
            return;
          });
        } else {
          // setGame(res.data);
          setGame({ ...Game, roomCode: roomcode });
          socket.emit("challengeOngoing");
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            type: "error",
            title: e.data,
            showConfirmButton: true,
          }).then(() => {
            return;
          });
        }
      });
  };
  /// user details start

  const [user, setUser] = useState();
  const [userAllData, setUserAllData] = useState();

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        // // console.log(res.data._id)
        Allgames(res.data._id);
        getCode(res.data._id);
        // setTimeout(() => {
        // }, 1000);
        // checkExpire();
        // if(!res.data.roomJoin)
        // {
        // }
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  };

  /// user details end

  const [ALL, setALL] = useState();
  // const [post, setPost] = useState([]);
  const [isConflict, setIsConflict] = useState(false);

  const Allgames = async (userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    await axios
      .get(baseUrl + `challange/${path}`, { headers })
      .then((res) => {
        if (res.data.status == "new" || res.data.status == "requested") {
          setTimeout(async () => {
            await axios
              .get(baseUrl + `challange/${path}`, { headers })
              .then((res) => {
                if (
                  res.data.status == "new" ||
                  res.data.status == "requested"
                ) {
                  history.push(props.location.state.prevPath);
                } else {
                  setProcess(false);
                }
              })
              .catch((error) => {
                console.error(error);
                history.push(props.location.state.prevPath);
              });
          }, 10000);
        } else {
          setProcess(false);
        }
        setALL(res.data);
        setGame(res.data);
        if (res.data.status === "conflict") {
          setIsConflict(true);
        }
        // // console.log(res.data.acceptedBy._id)
        if (userId == res.data.acceptedBy._id)
          setFecthStatus(res.data.acceptorStatus);

        if (userId == res.data.createdBy._id)
          setFecthStatus(res.data.creatorStatus);
      })
      .catch((e) => {
        console.log("All games error", e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  };

  const getCode = async (userId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `game/roomcode/get/${path}`, { headers })
      .then((res) => {
        //setALL(res.data)
        Allgames(userId);
        // if (res.data.acceptedBy == userId && res.data.roomCode == 0) {
        //   setTimeout(async () => {
        //     getCode();
        //   }, 10000);
        // }
      });
  };

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };
    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    let socket = new WebSocket(websocketURL);
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          if (socket._socketListeners[event])
            socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };
      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });
      socket.listen("ongoingChallenge", (data) => {
        getCode(user);
      });
      socket.listen("socketUpdate", (data) => {
        console.log("Update socket data i shere and the data is :- ", data);
        Allgames(user);
        console.log("Socket is triggered");

        setTimeout(() => {
          Allgames(user);
        }, 120000);
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");
        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);
          let socket = new WebSocket(websocketURL);
          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();
    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    // axios.get(baseUrl + "getmessage3").then((response) => {
    //   console.log("Message3 from admin is :- ", response);
    //   setPost(response.data);
    //   // console.log(setPost)
    // });

    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    // console.log(history.location)
    role();
    setTimeout(() => {
      Allgames(user);
    }, 120000);
  }, []);

  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
    setStatus(null);
    const modalCloseButton = document.querySelector(
      '[data-bs-dismiss="modal"]'
    );
    if (modalCloseButton) {
      modalCloseButton.click();
    }
  };

  // Result

  const Result = async (e) => {
    e.preventDefault();
    if (submitReq.current == false) {
      submitReq.current = true;
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      if (status) {
        setProcess(true);
        const formData = new FormData();
        formData.append("file", scrnshot);
        formData.append("status", status);
        if (status == "cancelled") {
          formData.append("reason", reason);
        }

        await axios({
          method: "post",
          url: baseUrl + `challange/result/${path}`,
          data: formData,
          headers: headers,
        })
          .then((res) => {
            console.log("Win submit api response isssssssssssssss", res);
            //  socket.emit('resultAPI');
            submitReq.current = false;
            setProcess(false);
            socket.emit("socketCall");
            // history.push(props.location.state.prevPath);
            window.location.href = props.location.state.prevPath;
          })
          .catch((e) => {
            console.log(e);
            if (e.response?.status == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("token");
              window.location.reload();
              history.push("/login");
            }
          });
      } else {
        submitReq.current = false;
        alert("please fill all field or Re-Select result status");
      }
    }
  };

  const copyCode = (e) => {
    // console.log(Game.roomCode);
    navigator.clipboard.writeText(Game.roomCode);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Room Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const Completionist = () => <span>You are good to go!</span>;

  // ADDED BY TEAM
  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    setScrnshot(e.target.files[0]);
  };

  // ADDED BY TEAM

  const [isChipClicked, setIsChipClicked] = useState(false);

  const handleChipClick = () => {
    setIsChipClicked(!isChipClicked);
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleReasonClick = (selectedReason) => {
    setReason(selectedReason);
    setIsClicked(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleButtonClick = () => {
    if (isChecked) {
      setStatus("lose");
    } else {
    }
  };

  console.log("setAll() data is :-", ALL);
  console.log("setGame()  data is :-", Game);
  return (
    <>
      <Header user={userAllData} />
      {/* {!Game && <div className="lds-ripple"><div></div><div></div></div>} */}
      {Game && (
        <div className="leftContainer">
          <div className="main-area" style={{ paddingTop: "75px" }}>
            {/* <div className="p-3 bg-white">
              <div
                style={{
                  background: "#FBD0D5",
                  borderRadius: "6px",
                  padding: "15px",
                  border: "1px solid red",
                }}
              ></div>
            </div> */}
            <div
              className="d-flex justify-content-between bg-white wallet-back "
              style={{ alignItems: "center" }}
            >
              <div className="ml-3">
                <Link to={"/landing"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="2em"
                    height="2em"
                    fill="currentColor"
                    color="#00D25B"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                    ></path>
                  </svg>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#00D25B",
                      fontSize: "14px",
                    }}
                  >
                    BACK
                  </span>
                </Link>
              </div>

              <div className="mr-3">
                <span
                  className={`${css.gamesSectionHeadline} text-uppercase  mt-2 font-weight-bold`}
                  style={{ right: "1.5rem", color: "#00D25B" }}
                >
                  <span style={{ fontSize: "14px" }}> Rules</span>
                  <NavLink to="/Rules">
                    <img
                      className="ml-2"
                      style={{ height: "20px", width: "20px" }}
                      src={process.env.PUBLIC_URL + "/Images/Homepage/info.png"}
                      alt=""
                    />
                  </NavLink>
                </span>
              </div>
            </div>

            {/* {post.length > 0 ? (
              <div
                className="messagebox-bg w-90 "
                style={{
                  borderRadius: "5px",
                  margin: "8px auto",
                  // width: "90%",
                  // border: "2px solid green",
                  textAlign: "left",
                }}
              >
                <p>
                  {post.map((element) => (
                    <p
                      className=" message-text mb-0 p-2 "
                      style={{
                        fontWeight: "900",
                        color: "#664D03",
                        fontSize: "14.4px",
                        textAlign: "justify",
                      }}
                    >
                      {element.message}
                    </p>
                  ))}
                </p>
              </div>
            ) : null} */}

            {!Boolean(submitProcess) && (
              <div className="p-3" style={{ backgroundColor: "#fff" }}>
                <div className="battleCard-bg">
                  <div className="battleCard">
                    <div
                      className=" p-2 mb-3"
                      style={{
                        backgroundColor: "#F6F8FA",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                      }}
                    >
                      <div className="players cxy pt-2">
                        <div className="flex-column cxy">
                          <h5 style={{ fontFamily: "Poppins" }}>
                            {Game.createdBy && Game.createdBy.name}
                          </h5>
                          {Game.createdBy.avatar ? (
                            <img
                              src={baseUrl + `${Game.createdBy.avatar}`}
                              // onError={(e) => {
                              //   e.target.onerror = null;
                              //   e.target.src =
                              //     "https://jaipurludo.com/user.png";
                              // }}
                              width="50px"
                              height="50px"
                              alt=""
                              style={{
                                borderTopLeftRadius: "50%",
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                borderBottomLeftRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={userimage}
                              width="50px"
                              height="50px"
                              alt=""
                              style={{
                                borderTopLeftRadius: "50%",
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                borderBottomLeftRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                        <img
                          className="mx-3"
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/Homepage/versus.png"
                          }
                          width="33px"
                          alt=""
                        />
                        <div className="flex-column cxy ">
                          <h5 style={{ fontFamily: "Poppins" }}>
                            {" "}
                            {Game.acceptedBy && Game.acceptedBy.name}
                          </h5>
                          {Game.acceptedBy.avatar ? (
                            <img
                              src={baseUrl + `${Game.acceptedBy.avatar}`}
                              width="50px"
                              height="50px"
                              alt=""
                              style={{
                                borderTopLeftRadius: "50%",
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                borderBottomLeftRadius: "50%",
                              }}
                            />
                          ) : (
                            <img
                              src={userimage}
                              width="50px"
                              height="50px"
                              alt=""
                              style={{
                                borderTopLeftRadius: "50%",
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                borderBottomLeftRadius: "50%",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="amount cxy mt-2">
                        <span style={{ opacity: "0.8", fontFamily: "Poppins" }}>
                          Playing for
                        </span>
                        <img
                          className="mx-1"
                          src={
                            process.env.PUBLIC_URL +
                            "/Images/LandingPage_img/global-rupeeIcon.pngRunning Battles"
                          }
                          width="25x"
                          alt=""
                        />
                        <span
                          style={{
                            fontSize: "1.2em",
                            fontWeight: 700,
                            opacity: "0.8",
                          }}
                        >
                          {Game.gameAmount}
                        </span>
                      </div>{" "}
                    </div>
                    {/* <div className="thin-divider-x my-3" /> */}

                    {(Game.roomCode == null && (
                      <div
                        className="roomCode cxy flex-column"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Waiting for Room Code...
                        <h6 style={{ fontFamily: "Poppins" }}>
                          रूम कोड का इंतजार है।
                        </h6>
                        <div className="lds-spinner  ">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <li
                          className="list-group-item rounded-2"
                          style={{ color: "red", marginTop: "5px" }}
                        >
                          <marquee>
                            कृपया लूडो किंग से रुम कोड निकल कर SET ROOM CODE मे
                            डाल दे
                          </marquee>
                        </li>
                      </div>
                    )) ||
                      (Game.roomCode != 0 && (
                        <div className="roomCodeAfter cxy ">
                          <div className="text-center d-flex flex-row  gap-2 justify-content-center ">
                            {/* <div  style={{ fontFamily: "Poppins" }}>
                              {" "}
                              Set Room Code
                            </div> */}
                            {/* <div style={{ fontFamily: "Poppins" }}>
                              {" "}
                              लूडो किंग से रूम कोड अपलोड करें{" "}
                            </div> */}
                            <p
                              className=" mb-0 text-black "
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "700",
                                marginBottom: "0",
                                paddingTop: "3px",
                                fontSize: "18px",
                              }}
                            >
                              Room Code
                            </p>

                            <span
                              className="text-black fw-bolder pt-1  "
                              style={{
                                fontFamily: "Poppins",
                                letterSpacing: "1px",
                                fontSize: "18px",
                              }}
                            >
                              {Game.roomCode}
                            </span>
                            <button
                              className="bg-green playButton position-static"
                              style={{
                                paddingLeft: "7px",
                                paddingRight: "7px",
                                textTransform: "capitalize",
                              }}
                              onClick={(e) => copyCode(e)}
                            >
                              Copy Code
                            </button>
                          </div>

                          {/* <li
                            className="list-group-item rounded-2 "
                            style={{ color: "#E15252", marginTop: "5px" }}
                          >
                            <marquee>
                              कृपया लूडो किंग से रुम कोड निकल कर SET ROOM CODE
                              मे डाल दे
                            </marquee>
                          </li> */}
                        </div>
                      )) ||
                      (Game.roomCode == 0 &&
                        ((Game.createdBy._id == user && (
                          <div
                            className="roomCode cxy flex-column"
                            style={{ fontFamily: "Poppins" }}
                          >
                            {console.log("current user", user)}
                            Set Room Code
                            <h6 style={{ fontFamily: "Poppins" }}>
                              लूडो किंग से रूम कोड अपलोड करें
                            </h6>
                            {/* <input
                            type="number"
                            className="form-control mt-1 w-75"
                            style={{
                              backgroundColor: "#e8eeee",
                              border: "1px solid #47a44780",
                            }}
                            value={roomcode}
                            onChange={(e) => setRoomcode(e.target.value)}
                          />
                          <button
                            className="bg-green playButton position-static mt-2"
                            style={{ fontFamily: "Poppins" }}
                            type="button"
                            onClick={() => getPost()}
                          >
                            SET ROOM CODE
                          </button> */}
                            <input
                              type="number"
                              className="form-control mt-1 w-50"
                              style={{
                                backgroundColor: "#e8eeee",
                                border: "1px solid #FE3347",
                              }}
                              value={roomcode}
                              onChange={(e) => setRoomcode(e.target.value)}
                            />
                            <button
                              className="bg-green playButton position-static mt-2"
                              style={{ fontFamily: "Poppins" }}
                              type="button"
                              onClick={() => {
                                if (/^0\d{7}$/.test(roomcode)) {
                                  getPost();
                                } else {
                                  Swal.fire({
                                    position: "bottom",
                                    title:
                                      "Please Enter A Valid 8-Digit Code That Starts With 0",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    customClass: {
                                      popup: "custom-popup-class",
                                      title: "custom-title-class",
                                    },
                                  });
                                }
                              }}
                            >
                              {loading ? "Loading..." : "SET ROOM CODE"}
                            </button>
                            <li
                              className="list-group-item rounded-2"
                              style={{
                                color: "#E15252",
                                marginTop: "5px",
                                width: "100%",
                              }}
                            >
                              <audio src={creatorAudio} autoPlay></audio>
                              <marquee>
                                <p>
                                  कृपया लूडो किंग से रुम कोड निकल कर SET ROOM
                                  CODE मे डाल दे
                                </p>
                              </marquee>
                            </li>
                          </div>
                        )) ||
                          (Game.acceptedBy._id == user && (
                            <div
                              className="roomCode cxy flex-column"
                              style={{ fontFamily: "Poppins" }}
                            >
                              Waiting for Room Code
                              <h6 style={{ fontFamily: "Poppins" }}>
                                रूम कोड का इंतजार है।
                              </h6>
                              <div className="lds-spinner mb-3">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                              <li
                                className="list-group-item"
                                style={{ color: "red", marginTop: "5px" }}
                              >
                                <audio src={acceptorAudio} autoPlay></audio>
                                <marquee>
                                  कृपया इन्तजार करें सामने वाला यूजर लूडो किंग
                                  से रूम कोड निकाल कर दे रहा है
                                </marquee>
                              </li>
                            </div>
                          ))))}

                    <div
                      className="cxy app-discription flex-column m-1 p-3 mt-3"
                      style={{
                        backgroundColor: "#F6F8FA",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        style={{
                          opacity: ".8",
                          fontFamily: "Dosis",
                          fontSize: "15px",
                        }}
                      >
                        {" "}
                        Play ludo game in Ludo King App
                      </span>
                      <div className="mt-2 d-flex">
                        <a
                          href="https://itunes.apple.com/app/id993090598"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="mr-2"
                            src={
                              process.env.PUBLIC_URL + "/Images/app-store.jpeg"
                            }
                            width="150px"
                            height="48px"
                            alt=""
                          />
                        </a>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.ludo.king"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            className="mr-2"
                            src={
                              process.env.PUBLIC_URL +
                              "/Images/google-play.jpeg"
                            }
                            width="150px"
                            height="48px"
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                    <div
                      className="  flex-column m-1 p-2"
                      style={{
                        backgroundColor: "#F6F8FA",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      <p
                        className="classic_manual mb-0"
                        style={{ fontFamily: "Poppins" }}
                      >
                        यह LudoClassicManual है यहा आप SET ROOM CODE मे दुसरे
                        गेम के कोड देते है या शेयर करते है तो टेबल कैंसल कर दिया
                        जाएगा और Penalty लगा दी जाए
                      </p>
                    </div>

                    <div className="rules mt-3 pb-3">
                      <h2
                        className="profile_headings ml-2 fw-bold fs-5 pb-3 pt-2"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Game Rules
                      </h2>
                      <ol className="list-group ">
                        <li
                          className="list-group-item"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: ".9rem",
                            backgroundColor: "#F6F8FA",
                            fontWeight: "400",
                          }}
                        >
                          Record every game while playing.
                        </li>
                        <li
                          className="list-group-item"
                          style={{
                            fontFamily: "Poppins",
                            fontSize: ".9rem",
                            backgroundColor: "#F6F8FA",
                            fontWeight: "400",
                          }}
                        >
                          For cancellation of game, video proof is necessary.
                        </li>
                        <li
                          className="list-group-item "
                          style={{
                            fontFamily: "Poppins",
                            fontSize: ".9rem",
                            backgroundColor: "#F6F8FA",
                            fontWeight: "400",
                          }}
                        >
                          ◉ महत्वपूर्ण नोट:कृपया गलत गेम परिणाम अपलोड न करें,
                          अन्यथा आपके वॉलेट बैलेंस पर penalty लगाई जायगी। गलत
                          रिजल्ट अपडेट करने पर 50 रुपये पेनल्टी लगेगी।
                        </li>
                        <li
                          className="list-group-item "
                          style={{
                            fontFamily: "Poppins",
                            fontSize: ".9rem",
                            backgroundColor: "#F6F8FA",
                            fontWeight: "400",
                          }}
                        >
                          ◉ महत्वपूर्ण नोट: यदि आप गेम के परिणामों को अपडेट नहीं
                          करते हैं, तो आपके वॉलेट बैलेंस पर जुर्माना लगाया
                          जाएगा। रिजल्ट अपडेट नहीं करने पर 25 रुपये पेनल्टी
                          लगेगी।
                        </li>
                      </ol>
                    </div>
                    <div className="match-status-border pt-0">
                      <div
                        className="col-12 profile_headings match_status_responsive  pt-0 p-2"
                        style={{
                          color: "rgb(13, 178, 91)",
                          fontSize: "1.4em",
                          fontFamily: "Poppins",
                        }}
                      >
                        Match status
                      </div>
                    </div>

                    <form
                      className="result-area ps-3"
                      onSubmit={(e) => {
                        Result(e);
                      }}
                      encType="multipart/form-data"
                    >
                      {fecthStatus !== null && fecthStatus !== undefined && (
                        <p>
                          You have already updated your battle result for{" "}
                          <h6 className="d-inline-block text-uppercase">
                            <b>{fecthStatus}</b>
                          </h6>
                        </p>
                      )}
                      {fecthStatus === null && (
                        <>
                          {" "}
                          <p className="pt-0 screenshot">
                            After completion of your game, select the status of
                            the game and post your screenshot below.
                          </p>
                          {/* {Game.roomCode !== 0 && isConflict && (
                                <div className="text-danger">
                                  Time Limit Exceed
                                </div>
                              )} */}
                          <div
                            className=" radios result_buttons_group d-flex gap-1  justify-content-between "
                            role="radiogroup"
                            aria-label="Result"
                          >
                            {/* win section  */}
                            {Game.roomCode != 0 && (
                              <label className="MuiFormControlLabel-root results_btn results_btn_win">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root jss8"
                                  aria-disabled="false"
                                >
                                  <span className="MuiTouchRipple-root" />
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                  style={{ marginTop: 3 }}
                                  onClick={(e) => setStatus("win")}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  I Won
                                </span>
                              </label>
                            )}
                            <>
                              {/* Modal */}
                              <div
                                className="modal fade modal-bottom"
                                id="exampleModal"
                                tabIndex={-1}
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog m-0 w-100"
                                  style={{ position: "fixed", bottom: "0" }}
                                >
                                  <div className="modal-content rounded-top-4">
                                    <div
                                      style={{ alignItems: "baseline" }}
                                      className="modal-header border-0 pb-0"
                                    >
                                      <div className="w-100">
                                        <h3
                                          style={{
                                            color: "#032995",
                                            fontFamily: "Poppins",
                                            fontSize: "18px",
                                          }}
                                          className="text-center"
                                        >
                                          Upload Result{" "}
                                        </h3>
                                      </div>
                                      <h1
                                        className="modal-title fs-5"
                                        id="exampleModalLabel"
                                      ></h1>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body pt-0">
                                      <div
                                        className="mt-4"
                                        style={{
                                          backgroundColor: "#28ac5b",
                                          border: "1px solid #28ac5b",
                                          padding: "3px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <div
                                          className="d-column justify-content-center"
                                          style={{ alignItems: "center" }}
                                        >
                                          {status !== null &&
                                            status !== "cancelled" &&
                                            status !== "lose" && (
                                              <div
                                                className={`${css.doc_upload}`}
                                                style={{
                                                  backgroundColor: "#28ac5b",
                                                  border: "1px solid #28ac5b",
                                                  padding: "10px",
                                                }}
                                              >
                                                <input
                                                  type="file"
                                                  onChange={handleChange}
                                                  accept="image/*"
                                                  required
                                                />
                                                {!scrnshot && (
                                                  <div className="cxy flex-column position-absolute ">
                                                    <i
                                                      style={{
                                                        color: "#fff",
                                                      }}
                                                      className="fa-solid fa-arrow-up"
                                                    ></i>

                                                    <div
                                                      className={`${css.sideNav_text} mt-2 `}
                                                      accordion
                                                      style={{
                                                        color: "#fff",
                                                        fontSize: "1.4em",
                                                        fontFamily: "Poppins",
                                                        fontWeight: "700",
                                                      }}
                                                    >
                                                      Upload screenshot.
                                                    </div>
                                                  </div>
                                                )}
                                                {scrnshot && (
                                                  <div className={css.uploaded}>
                                                    <img
                                                      src={file_icon}
                                                      width="26px"
                                                      alt=""
                                                      style={{
                                                        marginRight: "20px",
                                                      }}
                                                    />
                                                    <div
                                                      className="d-flex flex-column"
                                                      style={{
                                                        width: "80%",
                                                      }}
                                                    >
                                                      <div
                                                        className={`${css.name} text-white`}
                                                      >
                                                        {scrnshot.name}
                                                      </div>
                                                      <div className={css.size}>
                                                        {(
                                                          scrnshot.size /
                                                          1024 /
                                                          1024
                                                        ).toFixed(2)}{" "}
                                                        MB
                                                      </div>
                                                    </div>
                                                    <div className="image-block">
                                                      <img
                                                        src={global_cross}
                                                        width="10px"
                                                        alt=""
                                                        onClick={clearImage}
                                                      />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      {scrnshot1 ? (
                                        <div
                                          className="d-flex justify-content-center mt-3"
                                          style={{
                                            height: "250px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          <img
                                            src={scrnshot1}
                                            alt="screenshot1"
                                            style={{
                                              maxWidth: "75%",
                                              height: "50vh",
                                            }}
                                          />
                                        </div>
                                      ) : null}

                                      {/* <div className="w-100 mt-20">
                                        <img className="w-100" src="" alt="" />
                                      </div> */}

                                      {/* {fecthStatus == null &&
                                        fecthStatus == undefined && (
                                          <input
                                            type="Submit"
                                            className="btn btn-danger mt-3 text-white results_btn w-100"
                                            id="post"
                                          />
                                        )} */}
                                      <button
                                        type="submit"
                                        className="btn btn-danger mt-3 text-white w-100"
                                        style={{ borderRadius: "4px" }}
                                        id="post"
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          Result();
                                        }}
                                      >
                                        Post Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            {/* lost section */}
                            {Game.roomCode != 0 && (
                              <label className="MuiFormControlLabel-root results_btn results_btn_lose">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary"
                                  aria-disabled="false"
                                  root="[object Object]"
                                >
                                  <span className="MuiTouchRipple-root" />
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                  style={{ marginTop: 3 }}
                                  onClick={(e) => setStatus("lose")}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal1"
                                >
                                  I Lost
                                </span>
                              </label>
                            )}
                            <>
                              {/* Modal */}
                              <div
                                className="modal fade modal-bottom"
                                id="exampleModal1"
                                tabIndex={-1}
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog w-100 m-0  "
                                  style={{ position: "fixed", bottom: "0" }}
                                >
                                  <div className="modal-content rounded-top-4">
                                    <div className="modal-header border-0">
                                      <h1
                                        className="modal-title fs-5"
                                        id="exampleModalLabel"
                                      ></h1>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div>
                                        <h3
                                          style={{
                                            color: "#032995",
                                            fontFamily: "Poppins",
                                            fontSize: "18px",
                                          }}
                                          className="text-center"
                                        >
                                          Are you sure you Lost this game?
                                        </h3>
                                        <h3
                                          style={{
                                            color: "#032995",
                                            fontFamily: "Poppins",
                                            fontSize: "18px",
                                          }}
                                          className="text-center"
                                        >
                                          क्या आप निश्चित हैं कि आप यह गेम हार
                                          गए हैं?
                                        </h3>
                                      </div>

                                      <div
                                        className="d-flex gap-2  justify-content-center"
                                        style={{ alignItems: "center" }}
                                      >
                                        <div
                                          className="checkbox_game d-flex gap-2 mt-2"
                                          style={{ alignItems: "center" }}
                                        >
                                          <input
                                            type="checkbox"
                                            id="loast"
                                            name="loast"
                                            value={isChecked}
                                            onChange={handleCheckboxChange}
                                          />
                                          <p
                                            htmlFor="loast"
                                            className="mb-0 confirm_lable"
                                            style={{ fontSize: "25px" }}
                                          >
                                            {" "}
                                            Yes, I Confirm
                                          </p>
                                        </div>
                                      </div>

                                      <div>
                                        <button
                                          type="submit"
                                          className="btn btn-danger mt-4 text-white w-100"
                                          style={{ borderRadius: "5px" }}
                                          id="post"
                                          // onClick={(e) => setStatus("lose")}
                                          onClick={handleButtonClick}
                                          disabled={!isChecked}
                                        >
                                          Yes,I Lost
                                        </button>
                                      </div>
                                      <div>
                                        {/* <button
                                      className="no_lost_game_btn  w-100 pt-2 pb-2 mt-2"
                                      style={{ borderRadius: "3px" }}
                                      
                                    >
                                      No{" "}
                                    </button> */}
                                        <button
                                          type="button"
                                          className="  w-100 pt-2 pb-2 mt-2 bg-white border-1"
                                          style={{ borderRadius: "5px" }}
                                          data-bs-dismiss="modal"
                                        >
                                          No
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                            {/* cancel section */}
                            {  true && (
                              <label className="MuiFormControlLabel-root results_btn results_btn_cancel">
                                <span
                                  className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root"
                                  aria-disabled="false"
                                >
                                  <span className="MuiTouchRipple-root" />
                                </span>
                                <span
                                  className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                                  style={{ marginTop: 3, color: "black" }}
                                  onClick={(e) => setStatus("cancelled")}
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal2"
                                >
                                  Cancel
                                </span>
                              </label>
                            )}
                            <>
                              {/* Modal */}
                              <div
                                className="  modal fade modal-bottom"
                                id="exampleModal2"
                                tabIndex={-1}
                                position
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div
                                  className="modal-dialog m-0"
                                  style={{ position: "fixed", bottom: "0" }}
                                >
                                  <div className="modal-content rounded-top-4">
                                    <div className="modal-header border-0 ">
                                      <h1
                                        className="modal-title fs-5"
                                        id="exampleModalLabel"
                                      ></h1>
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      />
                                    </div>
                                    <div className="modal-body">
                                      <div>
                                        <h3
                                          style={{
                                            color: "#000",
                                            fontFamily: "Poppins",
                                            fontSize: "18px",
                                          }}
                                          className="text-center"
                                        >
                                          We Would Like to Know More
                                        </h3>
                                      </div>

                                      <h5
                                        style={{
                                          color: "#000",
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          marginBottom: "14px",
                                        }}
                                        className="text-center"
                                      >
                                        Select Reason For Cancelling
                                      </h5>

                                      <div
                                        id="pills-tab"
                                        role="tablist"
                                        className="nav nav-pills "
                                      >
                                        <div
                                          className={`MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4 `}
                                          tabIndex={0}
                                          role="button"
                                          id="reason1"
                                          onClick={() =>
                                            handleReasonClick("No Room code")
                                          }
                                        >
                                          <span
                                            className={`MuiChip-label MuiChip-labelMedium css-9iedg7 `}
                                          >
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-home-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-home"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-home"
                                                aria-selected="true"
                                              >
                                                {" "}
                                                No Room code
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>
                                        <div
                                          className={`MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4`}
                                          tabIndex={0}
                                          role="button"
                                          id="reason2"
                                          onClick={() =>
                                            handleReasonClick("Not joined")
                                          }
                                        >
                                          <span
                                            className={`MuiChip-label MuiChip-labelMedium css-9iedg7 `}
                                          >
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-profile-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-profile"
                                                aria-selected="false"
                                              >
                                                Not joined
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>
                                        <div
                                          className="MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4"
                                          tabIndex={0}
                                          role="button"
                                          id="reason3"
                                          onClick={() =>
                                            handleReasonClick("Not Playing")
                                          }
                                        >
                                          <span className="MuiChip-label MuiChip-labelMedium css-9iedg7">
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-contact-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-contact"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-contact"
                                                aria-selected="false"
                                              >
                                                Not Playing
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>
                                        <div
                                          className="MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4"
                                          tabIndex={0}
                                          role="button"
                                          id="reason3"
                                          onClick={() =>
                                            handleReasonClick(
                                              " Dont Want to Play"
                                            )
                                          }
                                        >
                                          <span className="MuiChip-label MuiChip-labelMedium css-9iedg7">
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-want-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-want"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-want"
                                                aria-selected="false"
                                              >
                                                Dont Want to Play
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>
                                        <div
                                          className="MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4"
                                          tabIndex={0}
                                          role="button"
                                          value="Opponent Abusing"
                                          id="reason3"
                                          onClick={() =>
                                            handleReasonClick(
                                              "Opponent Abusing"
                                            )
                                          }
                                        >
                                          <span className="MuiChip-label MuiChip-labelMedium css-9iedg7">
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-Abusing-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-Abusing"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-Abusing"
                                                aria-selected="false"
                                              >
                                                Opponent Abusing
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>

                                        <div
                                          className="MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4"
                                          tabIndex={0}
                                          role="button"
                                          id="reason3"
                                          onClick={() =>
                                            handleReasonClick(
                                              "Game not Started"
                                            )
                                          }
                                        >
                                          <span className="MuiChip-label MuiChip-labelMedium css-9iedg7">
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-Started-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-Started"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-Started"
                                                aria-selected="false"
                                              >
                                                Game not Started
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>
                                        <div
                                          className="MuiButtonBase-root MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-filledDefault cancel_reason_btns_bg css-6yx8q4"
                                          tabIndex={0}
                                          role="button"
                                          id="reason3"
                                          onClick={() =>
                                            handleReasonClick("Others")
                                          }
                                        >
                                          <span className="MuiChip-label MuiChip-labelMedium css-9iedg7">
                                            <li
                                              className="nav-item"
                                              role="presentation"
                                            >
                                              <button
                                                style={{
                                                  padding: "3px",
                                                  paddingInline: "11px",
                                                  textTransform: "math-auto",
                                                  fontFamily: "Poppins",
                                                }}
                                                className="nav-link cancel-buttons"
                                                id="pills-Started-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-Started"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-Started"
                                                aria-selected="false"
                                              >
                                                Others
                                              </button>
                                            </li>
                                          </span>
                                          <span className="MuiTouchRipple-root css-w0pj6f" />
                                        </div>
                                      </div>

                                      <form onSubmit={handleSubmit}>
                                        <button
                                          type="submit"
                                          className="btn btn-primary mt-3 text-white w-100"
                                          style={{ borderRadius: "8px" }}
                                          id="post"
                                        >
                                          <span
                                            style={{
                                              textTransform: "math-auto",
                                              fontFamily: "Poppins",
                                              fontSize: "16px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            Confirm
                                          </span>
                                        </button>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                          {/* <div
                          className="MuiFormGroup-root radios"
                          role="radiogroup"
                          aria-label="Result"
                        >
                          <label className="MuiFormControlLabel-root hidden Mui-disabled">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary jss2 Mui-checked jss3 Mui-disabled MuiIconButton-colorSecondary Mui-disabled Mui-disabled"
                              tabIndex={-1}
                              aria-disabled="true"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="select"
                                  defaultChecked
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label Mui-disabled MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              (Disabled option)
                            </span>
                          </label>
                          <label className="MuiFormControlLabel-root">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root jss8"
                              aria-disabled="false"
                              
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="win"
                                  onClick={(e) => setStatus("win")}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                              <span className="MuiTouchRipple-root" />
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              I Won
                            </span>
                          </label>
                          <label className="MuiFormControlLabel-root">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary"
                              aria-disabled="false"
                              root="[object Object]"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="lose"
                                  onClick={(e) => setStatus("lose")}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                              <span className="MuiTouchRipple-root" />
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              I Lost
                            </span>
                          </label>
                          <label className="MuiFormControlLabel-root">
                            <span
                              className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root"
                              aria-disabled="false"
                            >
                              <span className="MuiIconButton-label">
                                <input
                                  className="jss4 mr-2"
                                  name="battleResult"
                                  type="radio"
                                  defaultValue="cancelled"
                                  onClick={(e) => setStatus("cancelled")}
                                  style={{ transform: "scale(1.5)" }}
                                />
                              </span>
                              <span className="MuiTouchRipple-root" />
                            </span>
                            <span
                              className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"
                              style={{ marginTop: "3px" }}
                            >
                              Cancel
                            </span>
                          </label>
                        </div> */}
                        </>
                      )}
                      {/* {status !== null &&
                      status !== "cancelled" &&
                      status !== "lose" && (
                        <div className={`${css.doc_upload} mt-5`}>
                          
                          <input
                            type="file"
                            onChange={handleChange}
                            accept="image/*"
                            required
                          />
                          {!scrnshot && (
                            <div className="cxy flex-column position-absolute ">
                              <i className="fa-solid fa-arrow-up"></i>
                              
                              <div className={`${css.sideNav_text} mt-2 `}>
                                Upload screenshot.
                              </div>
                            </div>
                          )}
                          {scrnshot && (
                            <div className={css.uploaded}>
                              <img
                                src="/images/file-icon.png"
                                width="26px"
                                alt=""
                                style={{ marginRight: "20px" }}
                              />
                              <div
                                className="d-flex flex-column"
                                style={{ width: "80%" }}
                              >
                                <div className={`${css.name} `}>
                                  {scrnshot.name}
                                </div>
                                <div className={css.size}>
                                  {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
                                </div>
                              </div>
                              <div className="image-block">
                                <img
                                  src="/images/global-cross.png"
                                  width="10px"
                                  alt=""
                                  onClick={clearImage}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {status !== null && status == "cancelled" && (
                      <div className="form-group">
                        <textarea
                          className="form-control border-secondary"
                          name=""
                          id=""
                          rows="3"
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}
                          required
                        ></textarea>
                      </div>
                    )} */}

                      {/* ADDED BY TEAM */}
                      {/* <div style={{ width: "100%" }}>
                      <img src={scrnshot1} style={{ width: "100%" }} />
                    </div> */}
                      {/* ADDED BY TEAM */}

                      {/* <button type='submit' className='btn btn-danger mt-3 text-white' id="post" onSubmit={(e) => { e.preventDefault(); Result() }}>Post Result</button> */}
                      {/* {fecthStatus == null && fecthStatus == undefined && (
                      <input
                        type="submit"
                        className="btn btn-danger mt-3 text-white"
                        id="post"
                      />
                    )} */}
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {Boolean(submitProcess) && (
        <div className="leftContainer">
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust opacity for desired blur effect
              zIndex: 999, // Ensure it's above other content
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="loader"></div>
          </div>
        </div>
      )}
      <div className="divider-y"></div>
    </>
  );
}
