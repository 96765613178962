import React from "react";
import "../css/login.css";
import { Link } from "react-router-dom";
import "../css/register.css";

export default function Register() {
  return (
    <>
      <div className="leftContainer">
        <div className="main-area">
          <div style={{ overflowY: "hidden" }}>
            <div
              className="position-absolute w-100 center-xy mx-auto"
              style={{ paddingTop: "122px", zIndex: 0, paddingInline: "10px" }}
            >
              <div className="card w-100" style={{ border: "1px solid #ccc" }}>
                <div className="card-header p-2">
                  <div
                    className="text-center text-black"
                    style={{ fontFamily: "Poppins" }}
                  >
                    Register
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="bg-white cxy flex-column"
                    style={{
                      width: "100%",

                      borderRadius: "5px",
                    }}
                  >
                    <div className="text-left w-100">
                      <label
                        className="register-text m-text"
                        style={{ fontFamily: "Poppins" }}
                      >
                        Full Name
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        style={{
                          padding: "20px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                          borderColor:"#ccc"
                        }}
                        required type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div
                    className="bg-white cxy flex-column"
                    style={{
                      width: "100%",

                      borderRadius: "5px",
                    }}
                  >
                    <div className="text-left w-100">
                      <label
                        className="register-text m-text"
                        style={{ fontFamily: "Poppins" }}
                      >
                        phone Number
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        style={{
                          padding: "20px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                          borderColor:"#ccc"

                        }}
                         required type="tel"
                        className="form-control "
                      />
                    </div>
                  </div>
                  <div
                    className="bg-white cxy flex-column"
                    style={{
                      width: "100%",

                      borderRadius: "5px",
                    }}
                  >
                    <div className="text-left w-100">
                      <label
                        className="register-text m-text "
                        style={{ fontFamily: "Poppins" }}
                      >
                        Refer Code (Optional)
                      </label>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        style={{
                          padding: "20px",
                          backgroundColor: "#fff",
                          borderRadius: "10px",
                          borderColor:"#ccc"

                        }}
                        className="form-control"
                      />
                    </div>

                    <div>
                      <p
                        className="register-agree"
                        style={{ textDecoration: "none" }}
                      >
                        By Continuing, you agree to our <Link to={"/term-condition"}>Legal Terms</Link>{" "}
                        and you are 18 years or older.
                      </p>
                    </div>
                    <button
                      className="Login-button w-100 cxy mt-4"
                      style={{ padding: "8px" }}
                    >
                      Submit
                    </button>
                    <div>
                      <p className="login-agree">
                        Already have an account? <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
