// import { width } from '@mui/system';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import "../Components/Component-css/Header.css";
import css from "./Component-css/Nav.module.css";
import userimage from "../Avtar/user.png";

const w3_close = () => {
  const width = document.getElementById("mySidebar").offsetWidth;
  document.getElementById("mySidebar").style.left = `-${width}px`;
  document.getElementById("sidebarOverlay").style.display = "none";
};
const w3_open = () => {
  document.getElementById("mySidebar").style.left = "0";
  document.getElementById("sidebarOverlay").style.display = "block";
};

const Header = ({ loggedIn, isCommissionVisible }) => {
  const [user, setUser] = useState();

  const isMounted = useRef(true);
  const [socketApp, setSocketApp] = useState();

  const websocketURL = process.env.REACT_APP_SOCKET_API;
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const history = useHistory();

  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallButtonVisible, setIsInstallButtonVisible] = useState(true);

  useEffect(() => {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    const isBrowser = window.matchMedia("(display-mode: browser)").matches;
    setIsInstallButtonVisible(!isStandalone && isBrowser);

    const handleAppInstalled = () => {
      setIsInstallButtonVisible(false);
    };

    const handleAppUninstalled = () => {
      setIsInstallButtonVisible(true);
    };

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      console.log("before install event", event);
      setDeferredPrompt(event);
    };

    window.addEventListener("appinstalled", handleAppInstalled);
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("appinstalled", handleAppInstalled);
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      console.log("prompt function", deferredPrompt.prompt);
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
      });
    }
  };
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  document.title = WebSitesettings
    ? WebSitesettings.webTitle
    : "Play LudoKing And Win Real Cash";
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  }, []);

  const logout = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  // function openNav ()  {
  //     document.getElementById("mySidenav").style.width = "250px";
  //     document.getElementById("main").style.marginLeft = "250px";
  //     document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  //   };

  function openNav() {
    const element = document.getElementById("mySidenav");
    if (element) {
      element.style.width = "95vw";
      element.style.marginLeft = "0px";
      element.style.maxWidth = "450px";
    } else {
      console.error("Element is null or not found in the DOM.");
    }
  }
  function closeNav() {
    const element = document.getElementById("mySidenav");
    if (element) {
      element.style.width = "0";
      element.style.marginLeft = "0";
    } else {
      console.error("Element is null or not found in the DOM.");
    }
  }

  //  function  closeNav () {
  //     document.getElementById("mySidenav").style.width = "0";
  //     document.getElementById("main").style.marginLeft = "0";
  //     document.body.style.backgroundColor = "black";
  //   };

  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };


  useEffect(() => {
    getUser();
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socket = new WebSocket(websocketURL);

    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocketApp(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocketApp(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocketApp(undefined);
        }, 30000 + 1000);
      });
    
      socket.listen("socketUpdate", (data) => {
        getUser();
      });
      
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");

        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocketApp(undefined);

          let socket = new WebSocket(websocketURL);

          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocketApp(undefined);
      socket.close();
    };
  }, []);

  return (
    <div>
      {access_token ? (
        <React.Fragment>
          <div id="sidebarOverlay" onClick={w3_close}></div>
          <div
            className="w3-sidebar w3-bar-block w3-sidebar-login"
            id="mySidebar"
            style={{ paddingBottom: "70px" }}
          >
            <div id="mySidenav">
              <div className="canvasheader canvasheader-login">
                <div className="canvas-title">KD 124</div>
                <a href="javascript:void(0)" className="p-0" onClick={w3_close}>
                  <i className="fa-solid fa-xmark fs-icon"></i>
                </a>
              </div>
            </div>
            <div style={{ paddingTop: "66px" }}>
              <div
                className="p-2 d-flex justify-content-between"
                style={{ alignItems: "center" }}
              >
                <div className="hey-all">
                  <p className="hey-text m-0 hey-text-login">Hey ,</p>
                  <p className="hey-text m-0 hey-text-login">
                    {" "}
                    {`${user && user.name ? user.name.slice(0, 5) + "." : ""}`}
                  </p>
                  <img
                    style={{ height: "30px", width: "30px" }}
                    src={
                      process.env.PUBLIC_URL + "/Images/Header/hey-image.png"
                    }
                    alt=""
                  />
                </div>
                <Link to={"/Profile"} className="" onClick={w3_close}>
                  <picture className="icon">
                    {user && user.avatar ? (
                      <img
                        width="30px"
                        height="30px"
                        src={baseUrl + `${user && user.avatar}`}
                        alt="profile"
                        style={{ borderRadius: "50px" }}
                      />
                    ) : (
                      <img
                        src={userimage}
                        width="30px"
                        height="30px"
                        alt="profile"
                      />
                    )}
                  </picture>
                </Link>
              </div>

              <Link
                to={"/landing"}
                className="w3-bar-item w3-button"
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#F8F9FA",
                }}
                onClick={w3_close}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <img
                      alt="img"
                      src={process.env.PUBLIC_URL + "/Images/Header/Play.webp"}
                    />
                  </picture>
                  <p className="m-0 game-name">Play</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to={"/wallet"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <svg
                      style={{ color: "#212529" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="36"
                      height="36"
                      fill="currentColor"
                    >
                      <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"></path>
                    </svg>
                  </picture>
                  <p className="m-0 game-name" style={{ marginLeft: ".5rem" }}>
                    wallet
                  </p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to="/history"
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#F8F9FA",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <svg
                      style={{ color: "#212529" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="36"
                      height="36"
                      fill="currentColor"
                    >
                      <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"></path>
                      <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"></path>
                      <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"></path>
                    </svg>
                  </picture>
                  <p className="m-0 game-name">History</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to="/profile"
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <svg
                      style={{ color: "#212529" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="36"
                      height="36"
                      fill="currentColor"
                    >
                      <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"></path>
                    </svg>
                  </picture>
                  <p className="m-0 game-name">Profile</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>

              <Link
                to={"/refer"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#F8F9FA",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <svg
                      style={{ color: "#212529" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="36"
                      height="36"
                      fill="currentColor"
                    >
                      <path d="M3 2.5a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1 5 0v.006c0 .07 0 .27-.038.494H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 1 14.5V7a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h2.038A2.968 2.968 0 0 1 3 2.506V2.5zm1.068.5H7v-.5a1.5 1.5 0 1 0-3 0c0 .085.002.274.045.43a.522.522 0 0 0 .023.07zM9 3h2.932a.56.56 0 0 0 .023-.07c.043-.156.045-.345.045-.43a1.5 1.5 0 0 0-3 0V3zM1 4v2h6V4H1zm8 0v2h6V4H9zm5 3H9v8h4.5a.5.5 0 0 0 .5-.5V7zm-7 8V7H2v7.5a.5.5 0 0 0 .5.5H7z"></path>
                    </svg>
                  </picture>
                  <p className="m-0 game-name">Refer & Earn</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to={"/contact-us"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <img
                      alt="img"
                      src={
                        process.env.PUBLIC_URL + "/Images/Header/support.webp"
                      }
                    />
                  </picture>
                  <p className="m-0 game-name">Support</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to={"/term-condition"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#F8F9FA",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <svg
                      style={{ color: "#212529" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="36"
                      height="36"
                      fill="currentColor"
                    >
                      <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                      <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                    </svg>
                  </picture>
                  <p className="m-0 game-name">Legal Terms</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <div
            className="w3-teal"
            style={{ position: "fixed", zIndex: "1", top: "0", width: "480px" }}
          >
            <div className="w3-container">
              <div
                style={{
                  boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%)",
                  alignItems: "center",
                }}
                className={`${css.headerContainer} justify-content-between `}
              >
                <div className={`d-flex gap-1 shadow-0 p-0`}>
                  <button
                    className="w3-button w3-teal w3-xlarge float-left"
                    onClick={w3_open}
                    id="hambergar"
                  >
                    <svg
                      style={{ color: "black" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"
                      ></path>
                    </svg>
                  </button>
                  <Link to="/">
                    <picture className={`ml-1 ${css.navLogo} d-flex`}>
                      <img
                        src="/Images/LandingPage_img/Header_profile.jpg"
                        className="snip-img "
                        alt="Logo"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </picture>
                  </Link>
                </div>
                <div
                  className={`${css.menu_items}`}
                  style={{ position: "relative" }}
                >
                  {isInstallButtonVisible && (
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      style={{
                        position: "relative",
                        height: "39px",
                        right: "5px",
                      }}
                      onClick={handleInstall}
                    >
                      <i className="fa fa-download"></i>
                      Install App
                    </button>
                  )}

                  <Link
                    className={`${css.box} `}
                    to="/wallet"
                    style={{
                      borderRadius: "4px",
                      // minWidth: "83px",
                      height: "39px",
                    }}
                  >
                    <picture className={`${css.moneyIcon_container}`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="1em"
                        height="1em"
                        fill="green"
                        class="me-2 ms-2 "
                      >
                        <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v2h6a.5.5 0 0 1 .5.5c0 .253.08.644.306.958.207.288.557.542 1.194.542.637 0 .987-.254 1.194-.542.226-.314.306-.705.306-.958a.5.5 0 0 1 .5-.5h6v-2A1.5 1.5 0 0 0 14.5 2h-13z"></path>
                        <path d="M16 6.5h-5.551a2.678 2.678 0 0 1-.443 1.042C9.613 8.088 8.963 8.5 8 8.5c-.963 0-1.613-.412-2.006-.958A2.679 2.679 0 0 1 5.551 6.5H0v6A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-6z"></path>
                      </svg>
                    </picture>
                    <div style={{ marginTop: "10px" }}>
                      <div
                        className={`${css.moneyBox_text}`}
                        style={{ font: "Poppins", fontSize: "16px" }}
                      >
                        {user && user.walletBalance}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div id="sidebarOverlay" onClick={w3_close}></div>
          <div
            className="w3-sidebar w3-bar-block w3-sidebar-login"
            id="mySidebar"
            style={{ paddingBottom: "70px" }}
          >
            <div id="mySidenav">
              <div className="canvasheader canvasheader-login">
                <div className="canvas-title">KD 124</div>
                <a href="javascript:void(0)" className="p-0" onClick={w3_close}>
                  <i className="fa-solid fa-xmark fs-icon"></i>
                </a>
              </div>
            </div>
            <div style={{ paddingTop: "66px" }}>
              <div
                className="p-2 px-3 d-flex justify-content-between"
                style={{ alignItems: "center" }}
              >
                <div className="hey-all">
                  <p
                    className="hey-text m-0 hey-text-login fw-bold "
                    style={{ fontFamily: "Poppins", fontWeight: "950" }}
                  >
                    Hey ,
                  </p>
                  {/* <p className="hey-text m-0 hey-text-login"> {`${user && user.name ? user.name.slice(0, 3) + '...' : ''}`}</p> */}
                  <img
                    style={{ height: "34px", width: "34px" }}
                    src={
                      process.env.PUBLIC_URL + "/Images/Header/hey-image.png"
                    }
                    alt=""
                  />
                </div>
              </div>

              <Link
                to={"/login"}
                className="w3-bar-item w3-button"
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#F8F9FA",
                }}
                onClick={w3_close}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <img
                      alt="img"
                      src={process.env.PUBLIC_URL + "/Images/Header/Play.webp"}
                    />
                  </picture>
                  <p className="m-0 game-name">Play</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to={"/login"}
                className="w3-bar-item w3-button"
                style={{
                  justifyContent: "space-between",
                }}
                onClick={w3_close}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <img
                      alt="img"
                      src={process.env.PUBLIC_URL + "/Images/Header/Play.webp"}
                    />
                  </picture>
                  <p className="m-0 game-name">Register</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>

              <Link
                to={"/support"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                  backgroundColor: "#F8F9FA",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <img
                      alt="img"
                      src={
                        process.env.PUBLIC_URL + "/Images/Header/support.webp"
                      }
                    />
                  </picture>
                  <p className="m-0 game-name">Support</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
              <Link
                to={"/term-conditions"}
                className="w3-bar-item w3-button"
                onClick={w3_close}
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="d-flex"
                  style={{ alignItems: "center", gap: "12px" }}
                >
                  <picture className="icon">
                    <svg
                      style={{ color: "#212529" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="34"
                      height="34"
                      fill="currentColor"
                    >
                      <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
                      <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
                    </svg>
                  </picture>
                  <p className="m-0 game-name">Legal Terms</p>
                </div>
                <svg
                  style={{ fontSize: "24px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="m-0 p-0 d-flex align-items-center justify-content-center"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <div
            className="w3-teal"
            style={{ position: "fixed", zIndex: "1", top: "0", width: "480px" }}
          >
            <div className="w3-container">
              <div
                style={{
                  boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 8%)",
                  alignItems: "center",
                }}
                className={`${css.headerContainer} justify-content-between `}
              >
                <div className={`d-flex gap-1 shadow-0 p-0`}>
                  <button
                    className="w3-button w3-teal w3-xlarge float-left"
                    onClick={w3_open}
                    id="hambergar"
                  >
                    <svg
                      style={{ color: "black" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="24"
                      height="24"
                      fill="currentColor"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"
                      ></path>
                    </svg>
                  </button>
                  <Link to="/">
                    <picture className={`ml-1 ${css.navLogo} d-flex`}>
                      <img
                        src="/Images/LandingPage_img/Header_profile.jpg"
                        className="snip-img "
                        alt="Logo"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </picture>
                  </Link>
                </div>
                <div
                  className={`${css.menu_items}`}
                  style={{ position: "relative" }}
                >
                  <button
                    className="guide-btn d-flex "
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom"
                    aria-controls="offcanvasBottom"
                    style={{ alignItems: "center" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      class="me-2"
                    >
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                    </svg>
                    Guide
                  </button>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        // <div className="w3-teal ">
        //   <div className="w3-container ">
        //     <div style={{ position: "fixed", zIndex: "1", width: "100%" }}>
        //       <div className="row w-100 m-0 ms-0 me-0">
        //         <div className="col-lg-12 p-0">

        //         </div>
        //       </div>

        //       <div className={`${css.headerContainer} justify-content-between w-100`}>
        //         <div id="mySidenav" className="sidenav">
        //           <div className="canvasheader">
        //             <div className="canvas-title">KD 124</div>
        //             <a
        //               href="javascript:void(0)"
        //               className="p-0"
        //               onClick={closeNav}
        //             >
        //               <i className="fa-solid fa-xmark fs-icon"></i>
        //             </a>
        //           </div>
        //           <div className="hey p-4">
        //             <div className="hey-text">
        //               <div className="hey-all">
        //                 <p className="hey-text m-0">Hey ,</p>

        //                 <img
        //                   style={{ height: "30px", width: "30px" }}
        //                   src={
        //                     process.env.PUBLIC_URL +
        //                     "/Images/Header/hey-image.png"
        //                   }
        //                   alt=""
        //                 />
        //               </div>
        //             </div>
        //           </div>

        //           <div className="sidebar_icon">
        //             <Link
        //               to="/login"
        //               onClick={closeNav}
        //               className="d-flex"
        //               style={{
        //                 justifyContent: "space-between",
        //                 alignItems: "center",
        //                 backgroundColor: "#F8F9FA",
        //               }}
        //             >
        //               <a href="#" className="d-flex gap-4">
        //                 {" "}
        //                 <picture className="icon">
        //                   <img
        //                     alt="img"
        //                     src={
        //                       process.env.PUBLIC_URL +
        //                       "/Images/Header/Play.webp"
        //                     }
        //                   />
        //                 </picture>
        //                 Play
        //               </a>
        //               <svg
        //                 style={{ fontSize: "25px" }}
        //                 xmlns="http://www.w3.org/2000/svg"
        //                 height="1em"
        //                 viewBox="0 0 320 512"
        //               >
        //                 <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        //               </svg>
        //             </Link>
        //             <Link
        //               to="/login"
        //               onClick={closeNav}
        //               className="d-flex"
        //               style={{
        //                 justifyContent: "space-between",
        //                 alignItems: "center",
        //               }}
        //             >
        //               <a href="#" className="d-flex gap-4 ">
        //                 {" "}
        //                 <picture className="icon">
        //                   <img
        //                     alt="img"
        //                     src={
        //                       process.env.PUBLIC_URL +
        //                       "/Images/Header/Play.webp"
        //                     }
        //                   />
        //                 </picture>
        //                 Register
        //               </a>
        //               <svg
        //                 style={{ fontSize: "25px" }}
        //                 xmlns="http://www.w3.org/2000/svg"
        //                 height="1em"
        //                 viewBox="0 0 320 512"
        //               >
        //                 <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        //               </svg>
        //             </Link>
        //             <Link
        //               to="/support"
        //               onClick={closeNav}
        //               className="d-flex"
        //               style={{
        //                 justifyContent: "space-between",
        //                 alignItems: "center",
        //                 backgroundColor: "#F8F9FA",
        //               }}
        //             >
        //               <a href="#" className="d-flex gap-4 ">
        //                 <picture className="icon">
        //                   <img
        //                     alt="img"
        //                     src={
        //                       process.env.PUBLIC_URL +
        //                       "/Images/Header/support.webp"
        //                     }
        //                   />
        //                 </picture>
        //                 Support
        //               </a>
        //               <svg
        //                 style={{ fontSize: "25px" }}
        //                 xmlns="http://www.w3.org/2000/svg"
        //                 height="1em"
        //                 viewBox="0 0 320 512"
        //               >
        //                 <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        //               </svg>
        //             </Link>
        //             <Link
        //               onClick={closeNav}
        //               to="/term-conditions"
        //               className="d-flex"
        //               style={{
        //                 justifyContent: "space-between",
        //                 alignItems: "center",
        //               }}
        //             >
        //               <a href="#" className="d-flex gap-4 ">
        //                 {" "}
        //                 <picture className="icon">
        //                   <svg
        //                     style={{ color: "#212529" }}
        //                     xmlns="http://www.w3.org/2000/svg"
        //                     viewBox="0 0 16 16"
        //                     width="36"
        //                     height="36"
        //                     fill="currentColor"
        //                   >
        //                     <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"></path>
        //                     <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"></path>
        //                   </svg>
        //                 </picture>
        //                 Legal Terms
        //               </a>
        //               <svg
        //                 style={{ fontSize: "25px" }}
        //                 xmlns="http://www.w3.org/2000/svg"
        //                 height="1em"
        //                 viewBox="0 0 320 512"
        //               >
        //                 <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        //               </svg>
        //             </Link>
        //           </div>
        //         </div>

        //         <div className="d-flex gap-2 w-100">
        //           <span
        //             className=""
        //             style={{ fontSize: "30px", cursor: "pointer" }}
        //             onClick={openNav}
        //           >
        //             <svg
        //               style={{ color: "black" }}
        //               xmlns="http://www.w3.org/2000/svg"
        //               viewBox="0 0 16 16"
        //               width="24"
        //               height="24"
        //               fill="currentColor"
        //             >
        //               <path
        //                 fill-rule="evenodd"
        //                 d="M4.5 11.5A.5.5 0 0 1 5 11h10a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm-2-4A.5.5 0 0 1 1 3h10a.5.5 0 0 1 0 1H1a.5.5 0 0 1-.5-.5z"
        //               ></path>
        //             </svg>
        //           </span>

        //           <Link to="/">
        //             <picture className={`nav-logo ${css.navLogo} d-flex`}>
        //               <img
        //                 src="/Images/LandingPage_img/Header_profile.jpg"
        //                 className="snip-img mr-5"
        //                 alt="Logo"
        //                 id="hello"
        //                 style={{
        //                   width: "40px",
        //                   height: "40px",
        //                   marginTop: "6px",
        //                 }}
        //               />
        //             </picture>
        //           </Link>
        //         </div>
        //         <button
        //           className="guide-btn d-flex "
        //           type="button"
        //           data-bs-toggle="offcanvas"
        //           data-bs-target="#offcanvasBottom"
        //           aria-controls="offcanvasBottom"
        //           style={{alignItems:"center"}}
        //         >
        //          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" class="me-2"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path></svg>
        //           Guide
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}
    </div>
  );
};

export default Header;
