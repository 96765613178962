import React, { useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Kyc from "./Kyc";

const Mywallet = () => {
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  let access_token = localStorage.getItem("token");
  access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      history.push("/login");
    }
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });
  }, []);

  return (
    <>
      <div
        className="leftContainer shadow responsive-position "
        style={{ paddingTop: "55px" }}
      >
        {/* <div className={`${css.divider_x} XXsnipcss_extracted_selector_selectionXX snipcss0-0-0-1 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center`}></div> */}
        <div className="p-3">
          <div
            className="d-flex justify-content-between wallet-back "
            style={{ alignItems: "center" }}
          >
            <div>
              <Link to={"/landing"}>
                <button
                  className="d-flex justify-content-between"
                  style={{
                    alignItems: "center",
                    backgroundColor: "#4782F4",
                    padding: "8px",
                    paddingInline: "20px",
                    border: "none",
                    borderRadius: "5px",
                    color: "#fff",
                    fontFamily: "Poppins",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    class="me-2"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                    ></path>
                  </svg>
                  <span class="text-capitalize" style={{ fontWeight: "bold" }}>
                    Back
                  </span>
                </button>
              </Link>
            </div>
            <Link to="/history" className="wallet-history p-2">
              <div
                className={` ${css.mytext} text-muted`}
                style={{
                  border: "2px solid #4782F4",
                  padding: "8px",
                  paddingInline: "20px",

                  borderRadius: "6px",
                }}
              >
                {" "}
                <p
                  className="m-0"
                  style={{ color: "#4782F4", fontFamily: "Poppins" }}
                >
                  {" "}
                  Wallet History
                </p>
              </div>
            </Link>
          </div>
          <Kyc />
          <div className="card" style={{ border: "1px solid #ccc" }}>
            <div
              className="card-header text-center"
              style={{
                fontWeight: "500",
                fontSize: "15px",
                letterSpacing: "0.3px",
              }}
            >
              Deposit Chips
            </div>
            <div className="card-body p-2">
              <div>
                <div>
                  <div
                    className="text-center mb-2"
                    style={{
                      backgroundColor: "#b6d4fe",
                      color: "#084298",
                      fontSize: "0.7rem",
                      padding: "13px",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                    }}
                  >
                    यह चिप्स Win एवं Buy की गई चिप्स है इनसे सिर्फ़ गेम खेले जा
                    सकते है ॥ Bank या UPI में निकाला नहीं जा सकता है
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-2 pt-1">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span
                        class="text-capitalize fw-bold"
                        style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}
                      >
                        chips
                      </span>
                      <span class="fs-4">{user && user.addChip}</span>
                    </div>
                  </div>
                  <div className="pt-3">
                    <Link to="/addcase">
                      <button
                        className="w-100"
                        style={{
                          backgroundColor: "#4782F4",
                          border: "none",
                          padding: "13px",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                            letterSpacing: "0.5px",
                          }}
                        >
                          Add
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-3 mb-5" style={{ border: "1px solid #ccc" }}>
            <div
              className="card-header text-center"
              style={{
                fontWeight: "500",
                fontSize: "15px",
                letterSpacing: "0.3px",
              }}
            >
              Winning Chips
            </div>
            <div className="card-body p-2">
              <div>
                <div>
                  <div
                    className="text-center mb-2"
                    style={{
                      backgroundColor: "#b6d4fe",
                      color: "#084298",
                      fontSize: "0.7rem",
                      padding: "15px",
                      borderRadius: "8px",
                      fontFamily: "Poppins",
                    }}
                  >
                    यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है इन्हें
                    Bank या UPI में निकाला जा सकता है ॥ इन चिप्स से गेम भी खेला
                    जा सकता है
                  </div>
                  <div className="d-flex align-items-center justify-content-center px-2 pt-3">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span
                        class="text-capitalize fw-bold"
                        style={{ fontFamily: "Poppins", fontSize: "0.8rem" }}
                      >
                        chips
                      </span>
                      <span class="fs-4">{user && user.withdrawAmount}</span>
                    </div>
                  </div>
                  <div className="pt-3">
                    <Link to="/Withdrawopt">
                      <button
                        className="w-100"
                        style={{
                          backgroundColor: "#4782F4",
                          border: "none",
                          padding: "13px",
                          borderRadius: "10px",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "15px",
                            fontWeight: "700",
                            fontFamily: "Poppins",
                            letterSpacing: "0.5px",
                          }}
                        >
                          {" "}
                          Withdraw
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <div>
          <Rightcontainer />
        </div>
      </div>
    </>
  );
};
export default Mywallet;
