import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import axios from "axios";

const Penaltyprofile = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  //eslint-disable-next-line
  const [user, setUser] = useState();
  //use for pagination..
  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers }).then((res) => {
      setUser(res.data);

      //Allgames(res.data._id)
      Allgames(res.data._id, pageNumber, limit);
      // window.location.reload()
    });
  };

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, {
        headers,
      })
      .then((res) => {
        setGame(res.data.ress);
        setNumberOfPages(res.data.totalPages);
      });
  };

  useEffect(() => {
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit]);

  const getTotalPenaltySum = () => {
    if (cardData && cardData.length > 0) {
      return cardData
        .filter((card) => card.reqType === "penalty")
        .reduce((total, card) => total + card.amount, 0);
    } else {
      return 0;
    }
  };
  return (
    <>
      {cardData && (
        
          <div
            style={{
              color: "#000",
              fontWeight: "bold",
            }}
          >
            <span> {getTotalPenaltySum()}</span>
          </div>
      )}
    </>
  );
};

export default Penaltyprofile;
