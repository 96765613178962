import React, { useEffect, useRef, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Homepage from "./uiComponents/Homepage";
import "./css/Loader.css";
import Landing from "./uiComponents/Landing";
import userLogin from "./uiComponents/Login";
import Mywallet from "./uiComponents/Mywallet";
import Addcase from "./uiComponents/Addcase";

import Withdrawopt from "./uiComponents/Withdrawopt";
import Profile1 from "./uiComponents/Profile1";
import ViewGame1 from "./uiComponents/ViewGame1";
import Gamehistory from "./uiComponents/Gamehistory";
import "animate.css";
import axios from "axios";

import Transactionhistory from "./uiComponents/Transactionhistory";
import Referralhis from "./uiComponents/Referralhis";
import Refer from "./uiComponents/Refer";
import Notification from "./uiComponents/Notification";
import Support from "./uiComponents/Support";
import Guidebtn from "./uiComponents/Guidebtn";

// import Games from './uiComponents/Games';
// import Kyc from './uiComponents/Kyc';
import Kyc2 from "./uiComponents/Kyc2";
// import kyc3 from './uiComponents/kyc3';
import RefundPolicy from "./uiComponents/RefundPolicy";
import terms_condition from "./uiComponents/terms_condition";
import PrivacyPolicy from "./uiComponents/PrivacyPolicy";
import Gamerules from "./uiComponents/Gamerules";
import ResponsibleGaming from "./uiComponents/ResponsibleGaming";
import Return from "./uiComponents/Return";
import Notify from "./uiComponents/Notify";
import Header from "./Components/Header";
import Rightcontainer from "./Components/Rightcontainer";
// import Downloadbutton from "./Components/Downloadbutton";
import Redeem from "./uiComponents/Redeem";
import AboutUs from "./uiComponents/AboutUs";
import History from "./uiComponents/History";
import Terminategame from "./uiComponents/Terminategame";
import Register from "./uiComponents/Register";
import terms_condition1 from "./uiComponents/terms_condition1";
import Support1 from "./uiComponents/Support1";
import Commission from "./uiComponents/Commission";
import maintenence from "./uiComponents/maintenence.jpg";
import { Toaster } from "react-hot-toast";
import BottomNav from "./Components/BottomNav";

const App2 = () => {
  const location = useLocation();
  const isLandingPage =
    location.pathname === "/landing" ||
    location.pathname === "/" ||
    location.pathname === "/Homepage/Ludo Classics";

  //const [mount, setMount] = useState(true)
  //const history = useHistory()
  // const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(true);
  const [websiteStatus, setWebsiteStatus] = useState(false);
  const [withdrawsection, setwithdrawsection] = useState(true);
  const [withdrawOutput, setwithdrawOutput] = useState(false);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const websocketURL = process.env.REACT_APP_SOCKET_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [socketApp, setSocketApp] = useState();

  const access_token = localStorage.getItem("token");
  const [user, setUser] = useState();
  const getWebsiteStatus = () => {
    axios
      .get(baseUrl + "settings/data")
      .then((response) => {
        setLoading(false);
        setwithdrawsection(false);
        setWebsiteStatus(response.data.websiteStatus);
        setwithdrawOutput(response.data.withdrawOutput);
      })
      .catch((error) => {
        console.error("Error fetching settings:", error);
      });
  };


  const getUser = () => {
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          // history.pushState("/login")
        }
      });
  };


  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socket = new WebSocket(websocketURL);

    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocketApp(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocketApp(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocketApp(undefined);
        }, 30000 + 1000);
      });
      
      socket.listen("updateWithDrowDisable", (data) => {
        getWebsiteStatus();
      });
      
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");

        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocketApp(undefined);

          let socket = new WebSocket(websocketURL);

          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocketApp(undefined);
      socket.close();
    };
  }, []);


  useEffect(() => {
    getUser();
    getWebsiteStatus();
    // eslint-disable-next-line
  }, []);



  return (
    <>
      <Toaster />
      {loading ? (
         <>
         <div className="leftContainer">
           <div
           style={{
             position: "fixed",
             top: 0,
             left: 0,
             right: 0,
             bottom: 0,
             backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust opacity for desired blur effect
             zIndex: 999, // Ensure it's above other content
             display: "flex",
             justifyContent: "center",
             alignItems: "center"
           }}
         >
           <div class="loader"></div>
           </div>
         </div>
       </>
      ) : !websiteStatus ? (
        <div className="w-100 text-center ">
          <img
            className="d-block "
            style={{ width: "28rem" }}
            src={maintenence}
            alt="maintenence"
          />
        </div>
      ) : (
        <>
          <div className="leftContainer">
            <div>
              <Guidebtn />
              {!isLandingPage && <Header user={user} />}
              {/* <Commission/> */}
              {/* {access_token ?  <BottomNav/> : ""} */}
              
            </div>
          </div>

          {!access_token ? (
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/landing" component={Landing} />
              <Route path="/login" component={userLogin} />
              <Route path="/RefundPolicy" component={RefundPolicy} />
              <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
              <Route path="/term-condition" component={terms_condition} />
              <Route path="/term-conditions" component={terms_condition1} />
              <Route path="/about" component={AboutUs} />
              <Route path="/refund-policy" component={RefundPolicy} />
              <Route path="/support" component={Support1} />
              <Route path="/Gamerules" component={Gamerules} />
              <Route path="/responsible-gaming" component={ResponsibleGaming} />
              <Route path="/terminate-game" component={Terminategame} />
              <Route path="/register" component={Register} />

              <Redirect to="/login" />
            </Switch>
          ) : (
          
            <Switch>
              <Route
                path="/transaction-history"
                component={Transactionhistory}
              />
              {/* <Route exact path="/transaction" component={Transactionhistory} /> */}
              <Route exact path="/Referral-history" component={Referralhis} />
              <Route exact path="/landing" component={Landing} />
              <Route exact path="/Gamehistory" component={Gamehistory} />
              {/* <Route exact path="/profile" component={Profile} /> */}
              <Route
                exact
                path="/HomePage/:Game"
                render={() => <Homepage walletUpdate={getUser} />}
              />
              <Route exact path="/refer" component={Refer} />
              <Route exact path="/Notification" component={Notification} />
              <Route exact path="/" component={Landing} />
              <Route path="/profile" component={Profile1} />
              <Route path="/viewgame1/:id" component={ViewGame1} />
              <Route
                path="/addcase"
                render={() => <Addcase walletUpdate={getUser} />}
              />

              <Route path="/wallet" component={Mywallet} />
              <Route path="/support" component={Support1} />

              {/* <Route path="/Games" component={Games} /> */}
              <Route exact path="/landing/:id" component={Landing} />
              <Route path="/kyc2" render={() => <Kyc2 user={user} />} />
              <Route path="/Rules" component={Gamerules} />
              <Route path="/RefundPolicy" component={RefundPolicy} />
              <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
              <Route path="/term-condition" component={terms_condition} />
              <Route path="/term-conditions" component={terms_condition1} />
              {/* <Route path="/timer" component={Timer}/> */}
              <Route
                path="/return"
                render={() => <Return walletUpdate={getUser} />}
              />
              <Route path="/notify" component={Notify} />

              <Route
                path="/Redeem"
                render={() => <Redeem walletUpdate={getUser} />}
              />
              <Route path="/contact-us" component={Support} />
              <Route path="/refund-policy" component={RefundPolicy} />
              <Route path="/Gamerules" component={Gamerules} />
              <Route path="/responsible-gaming" component={ResponsibleGaming} />
              <Route path="/about" component={AboutUs} />
              <Route path="/history" component={History} />
              <Route path="/terminate-game" component={Terminategame} />
              <Route path="/register" component={Register} />
              {/* {withdrawsection ? (
                <p>Withdraw loading...</p>
              ) : !withdrawOutput ? (
                <p style={{ paddingTop: "75px", fontSize: "13px" }}>
                  असुविधा के लिए खेद है, कृपया सभी प्लेयर्स से अनुरोध है की
                  धैर्य बनाए रखे आपका अमाउंट सेफ है|
                </p>
              ) : (
                
              )} */}
              <Route
                path="/Withdrawopt"
                render={() => (
                  <Withdrawopt
                    walletUpdate={getUser}
                    isWithdrawEnabled={withdrawOutput}
                  />
                )}
              />
              <Redirect to="/landing" />
            </Switch>
            
          )}
          <div className="rightContainer">
            <Rightcontainer />
            {access_token ?  <BottomNav/> : ""}
          </div>

          {/* <h2>Install Demo</h2> */}
        </>
      )}
    </>
  );
};
export default App2;
