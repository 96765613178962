import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Swal from "sweetalert2";
import "../css/kyc.css";

const Kyc = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();

  const [holderName, setHolder_name] = useState();
  const [accountNumber, setAccount_number] = useState();
  const [ifscCode, setIfsc_code] = useState();
  const [upiId, setUpi_id] = useState();
  const history = useHistory();
  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        //window.location.reload();
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.name);
        setCode(res.data.referral);
        setHolder_name(res.data.holderName);
        setAccount_number(res.data.accountNumber);
        setIfsc_code(res.data.ifscCode);
        setUpi_id(res.data.upiId);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [name, setName] = useState();
  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          name,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };
  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holderName,
          accountNumber,
          ifscCode,
          upiId,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          referral,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "Invalid referral Code";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  //// total game
  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        setTotal(res.data);
      })
      .catch((e) => {});
  };

  const [pic, setPic] = useState();
  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");

      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      await fetch(baseUrl + `users/me/avatar`, {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }
  };

  useEffect(() => {
    UserALL();
  }, []);

  return (
    <>
   {profile && profile.verified !== "verified" && (
  <div className="mb-2" style={{ background: "white" }}>
    <div className="" style={{ background: "#F8D7DA", borderRadius: "5px" }}>
      <Link
        className={` w-100 p-3 d-block`}
        to={
          profile && profile.verified === "unverified"
            ? "/Kyc2"
            : "/Profile"
        }
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className={` ${css.mytext} kyc-status`}>
            {profile && profile.verified === "unverified" ? (
              <div>
                KYC Pending{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="20"
                  height="20"
                  fill="red"
                >
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                </svg>
              </div>
            ) : profile && profile.verified === "pending" ? (
              "In Process"
            ) : (
              "Verified ✅"
            )}
          </div>

          {profile && profile.verified === "unverified" ? (
            <div>
              <button className="kyc-btn">Complete Here</button>
            </div>
          ) : profile && profile.verified === "pending" ? (
            <div>
              <button className="kyc-btn" disabled>
                Verification in Process
              </button>
            </div>
          ) : (
            <div>
              <button className="kyc-btn-verified" disabled>
                Verification Completed
              </button>
            </div>
          )}
        </div>
      </Link>
    </div>
  </div>
)}

    </>
  );
};
export default Kyc;
