import React, { useEffect, useState } from "react";
import "../css/refhistory.css";
import axios from "axios";
import commission_gift from "../../assets/images/history_icons/commission_gift.png";
import ReactPaginate from "react-paginate";

const Referralhis = ({ showDefaultImage, data1, isPropData }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [user, setUser] = useState();

  const limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers }).then((res) => {
      setUser(res.data);
      Allgames(res.data.referralCode, pageNumber, limit);
      // window.location.reload()
    });
  };
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    if (isPropData) {
      let referralHist = [];
      data1.forEach((val) => {
        console.log("Data at referral entry is:--", val);
        if (val.earnedFrom) {
          console.log("inside if statement.....");
          referralHist.push(val);
        }
      });
      console.log("Data from props.....at referral......");
      setGame(referralHist);
    } else {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      await axios
        .get(
          baseUrl +
            `referral/code/win/${id}?page=${pageNumber}&_limit=${limit}`,
          { headers }
        )
        .then((res) => {
          if (res.data.message) {
            setGame([]);
            setNumberOfPages(0);
            return;
          }
          console.log("Data from api.....at referral......");
          setGame(res.data);
          setNumberOfPages(res.data.totalPages);
          //console.log(res.data)
        });
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",

      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };
  useEffect(() => {
    role();
    //eslint-disable-next-line
  }, [data1]);

  if (cardData == undefined) {
    return null;
  }

  return (
    <div>
      <div
        className="leftContainer"
        style={{ height: "100%", marginBottom: "100px" }}
      >
        {/* game-cards */}
        {cardData &&
          cardData.map((card) => {
            return (
              // <div
              //   className={`w-100 py-3 d-flex align-items-center ${css.list_item}`}
              //   key={card._id}
              // >
              //   {/* map the cardData */}
              //   <div className={`${css.center_xy} ${css.list_date} mx-4`}>
              //    <div>{dateFormat(card.createdAt).split(',')[0]}</div>

              //   </div>
              //   <div className={`${css.list_divider_y}`} />
              //   <div className={`mx-3 d-flex ${css.list_body}`}>
              //     <div className="d-flex align-items-center">

              //     </div>

              //     <div className="d-flex flex-column font-8">
              //       <div>
              //          <b>
              //         Referral earning
              //             </b>.
              //       </div>
              //       <div className={`${css.games_section_headline}`}>
              //         Earned by:{card.earned_from.name}
              //       </div>
              //     </div>
              //   </div>

              //  <div className="right-0 d-flex align-items-end pr-3 flex-column">

              //     <div className="d-flex float-right font-8">
              //       <div className="text-danger">{card.losestatus}</div>
              //       <div className="text-success">{card.winstatus}</div>
              //       <picture className="ml-1 mb-1">
              //         <img height="21px" width="21px" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/global-rupeeIcon.png' } alt="" />
              //       </picture>
              //       <span className="pl-1">{card.amount}</span>
              //     </div>
              //     <div
              //       className="games-section-headline"
              //       style={{ fontSize: "0.6em",whiteSpace:'nowrap'}}
              //     >
              //       Closing Balance: {card.closingBalance}
              //     </div>
              //   </div>
              // </div>
              <div
                className="px-1 py-2 w-100"
                style={{ borderBottom: "1px solid #ccc" }}
              >
                <div className="d-flex align-items-stretch w-100">
                  <div
                    className=" d-flex flex-column align-items-start justify-content-center border-end "
                    // style="width: 80px;"
                    style={{ width: "80px" }}
                  >
                    <span>
                      <img height={22} src={commission_gift} alt="/" />
                    </span>
                    <span
                      className="text-start"
                      style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                    >
                      {dateFormat(card.createdAt).split(",")[0]}
                    </span>
                    <span
                      className="text-start"
                      style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                    >
                      {dateFormat(card.createdAt).split(",")[1]}
                    </span>
                  </div>
                  <div className="d-flex flex-column align-items-stretch justify-content-center flex-1 w-100 px-2">
                    <div className="d-flex align-items-stretch justify-content-between">
                      <div className="d-flex flex-column">
                        <span
                          className="fw-semibold text-capitalize text-start"
                          // style="font-size: 0.9rem;"
                          style={{
                            fontSize: "0.8rem",
                            fontFamily: "Poppins",
                          }}
                        >
                          {/*Referral from {card.earned_from?.name} */}
                          Referral from{" "}
                          {card.earnedFrom ? card.earnedFrom.name : ""}
                        </span>
                      </div>
                      <div
                        className="d-flex flex-column align-items-end justify-content-center"
                        style={{ width: "85px" }}
                      >
                        <span
                          className=" text-end fw-bold my-1"
                          // style="font-size: 0.8rem;"
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "bold",
                            color: "#198754",
                          }}
                        >
                          (+) {card.amount}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      {/* <span
                        className="text-start text-capitalize"
                        style={{ fontSize: "0.7rem", fontFamily: "Poppins" }}
                      >
                        Earned by:{card.earned_from.name}
                      </span> */}
                      <span
                        className="text-start"
                        style={{ fontSize: "0.7rem", fontFamily: "Poppins" }}
                      >
                        Closing Balance: {card.closingBalance}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {/* pagination */}
        {/* <div className="">
          <div className="mt-5">
            <ReactPaginate
              previousLabel={"<< Previous"}
              nextLabel={"Next >>"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={
                "pagination justify-content-center custom-pagination-container"
              }
              pageClassName={"page-item custom-page-item"}
              pageLinkClassName={"page-link custom-page-link"}
              previousClassName={"page-item custom-previous-item"}
              previousLinkClassName={"page-link custom-previous-link"}
              nextClassName={"page-item custom-next-item"}
              nextLinkClassName={"page-link custom-next-link"}
              breakClassName={"page-item custom-break-item"}
              breakLinkClassName={"page-link custom-break-link"}
              activeClassName={"active custom-active"}
            />
          </div>
        </div> */}

        {cardData &&
          cardData.length === 0 &&
          (showDefaultImage ? (
            <div className="text-center">
              <img
                src={process.env.PUBLIC_URL + "/Images/refer_null.png"}
                alt="no data"
                className="img-fluid"
                style={{ marginTop: "25%" }}
              />
            </div>
          ) : (
            ""
          ))}
      </div>
    </div>
  );
};

export default Referralhis;
