import React, { useEffect, useState } from "react";
import "../css/layout.css";
import css from "../css/Profile.module.css";
import "../css/Profile1.css";
import { Link, useHistory } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import axios from "axios";
// import { Link, useHistory } from 'react-router-dom';
// import Profile from './Profile';
// import { Image } from 'react-bootstrap';
import Rightcontainer from "../Components/Rightcontainer";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import Penaltyprofile from "./Penaltyprofile";
import userimage from "../Avtar/user.png";

const Profile1 = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [show, setShow] = useState(false);

  const [referral, setCode] = useState("");
  const [Id, setId] = useState(null);
  const [profile, setProfile] = useState();
  const [portcss, setPortcss] = useState(css.active_tab);
  const [portcss1, setPortcss1] = useState(css.inactive_tab);
  const [crushcss, setCrushcss] = useState(true);
  const [holderName, setHolder_name] = useState();
  const [accountNumber, setAccount_number] = useState();
  const [ifscCode, setIfsc_code] = useState();
  const [upiId, setUpi_id] = useState();
  const history = useHistory();
  const logout = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `logout`,
        {
          headers: headers,
        },
        { headers }
      )
      .then((res) => {
        // setUser(res.data)
        localStorage.removeItem("token");
        //window.location.reload();
        history.push("/login");
        window.location.reload(true);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const UserALL = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setProfile(res.data);
        setId(res.data._id);
        TotalGame(res.data._id);
        setName(res.data.name);
        setCode(res.data.referral);
        setHolder_name(res.data.holderName);
        setAccount_number(res.data.accountNumber);
        setIfsc_code(res.data.ifscCode);
        setUpi_id(res.data.upiId);
      })
      .catch((e) => {
        // alert(e.msg)
      });
  };
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(profile && profile.name);
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    setEditing(false);
    // Perform save operation or update profile logic
    UpdateProfile(Id);
    window.location.reload(); // This might not be the best way to handle this, consider alternatives
  };

  const UpdateProfile = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          name,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.data == "User name already exist!") {
          Swal.fire({
            title: res.data,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setName(res.data);
          UserALL();
        }
      });
  };
  const updateBankDetails = async (e) => {
    e.preventDefault();
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          holderName,
          accountNumber,
          ifscCode,
          upiId,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          setShow((prev) => !prev);
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "something went wrong";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const update_RefCode = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const data = await axios
      .patch(
        baseUrl + `user/edit`,
        {
          referral,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status == 200) {
          UserALL();
          let message;
          message = res.data.msg;
          if (!res.data.msg) {
            message = "Invalid referral Code";
          }
          Swal.fire({
            title: message,
            icon: res.data.submit ? "success" : "error",
            confirmButtonText: "OK",
          });
        }
      });
  };
  //// total game
  const [total, setTotal] = useState();
  const TotalGame = (Id) => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `total/user/all/${Id}`, { headers })
      .then((res) => {
        if (res.data.message) {
          setTotal(0);
        } else {
          setTotal(res.data);
        }
      })
      .catch((e) => { });
  };

  const [pic, setPic] = useState();
  const Result = async (file) => {
    if (file) {
      const access_token = localStorage.getItem("token");
      // // console.log(access_token)
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      const formData = new FormData();
      formData.append("avatar", file);

      // console.log(formData)
      await fetch(baseUrl + `users/me/avatar`, {
        method: "POST",
        body: formData,
        headers,
      }).then((res) => {
        UserALL();
      });
    }

    // await axios.patch(baseUrl+`challange/result/${path}`,
    //   {
    //     status: status
    //   },
    //   { headers })
    //   .then((res) => {
    //     // getPost(res.data)
    //     // console.log(res.data);
    //     history.push("/landing")
    // }).catch((e) => {
    //     // console.log(e);
    //   })
  };
  //avatar

  useEffect(() => {
    UserALL();
  }, []);

  return (
    <>
      {/* <Header user={profile} /> */}
      <div
        className="leftContainer"
        style={{ height: "100%", paddingTop: "60px" }}
      >
        <div className=" pt-0" style={{ padding: "1rem" }}>
          <div className="card mt-3" style={{ border: "1px solid #ccc" }}>
            <div
              className="card-header text-center"
              style={{
                fontWeight: "500",
                fontSize: "15px",
                letterSpacing: "0.3px",
                padding: "0.25rem 1.25rem"
              }}
            >
              Profile
            </div>
            <div className="card-body p-3">
              <div className="text-center">
                <label>
                  <input
                    className="d-none"
                    type="file"
                    onChange={(e) => Result(e.target.files[0])}
                    accept="image/*"
                    required
                  />
                  <picture>
                    {/* {image} */}
                    {/* {console.log(process.env.PUBLIC_URL)} */}
                    {profile && profile.avatar ? (
                      <img
                        height="60px"
                        width="60px"
                        src={baseUrl + `${profile && profile.avatar}`}
                        alt=""
                        style={{ borderRadius: "50px" }}
                      />
                    ) : (
                      <img
                        height="60px"
                        width="60px"
                        src={userimage}
                        alt=""
                        style={{ borderRadius: "50px" }}
                      />
                    )}
                  </picture>
                </label>
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center mb-3">
                <label
                  style={{ fontFamily: "Poppins" }}
                  className="form-label text-capitalize"
                >
                  username
                </label>
                <div className="align-self-stretch d-flex align-items-center w-100 ">
                  {editing ? (
                    <div className="d-flex w-100">
                      <div
                        className={`${css.MuiFormControl_root} ${css.MuiTextField_root}`}
                      >
                        <div
                          className={`${css.MuiInputBase_root} ${css.MuiInput_root} ${css.MuiInputBase_formControl} ${css.MuiInput_formControl}`}
                        >
                          <input
                            type="text"
                            className="form-control custom-form-control me-2"
                            maxLength="20"
                            value={name}
                            maxlength="20"
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const nameWithoutSpaces = inputValue.replace(/\s/g, '');
                              setName(nameWithoutSpaces);
                            }}
                            style={{
                              border: "1px solid #ced4da",
                              borderRadius: "0.375rem",
                              backgroundColor: "#e9ecef",
                              fontFamily: "Poppins",
                              width: "348px",
                            }}
                          />
                        </div>
                      </div>
                      <button
                        className="btn text-capitalize btn-sm align-self-stretch custom-save-btn"
                        style={{
                          width: "75px",
                          backgroundColor: "#157347",
                          color: "white",
                        }}
                        onClick={handleSaveClick}
                      >
                        <p className="m-0" style={{ color: "white" }}>
                          Save
                        </p>
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between  align-items-center w-100">
                      <input
                        type="text"
                        className="form-control me-2"
                        maxLength="20"
                        value={profile && profile.name}
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: "0.375rem",
                          backgroundColor: "#e9ecef",
                          fontFamily: "Poppins",
                          width: "100%",
                        }}
                        readOnly
                      />
                      <button
                        className="btn text-capitalize btn-sm align-self-stretch"
                        style={{
                          width: "75px",
                          backgroundColor: "#0d6efd",
                          color: "white",
                        }}
                        onClick={handleEditClick}
                      >
                        <p className="m-0" style={{ color: "white" }}>
                          Edit
                        </p>
                      </button>
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex flex-column align-items-start justify-content-center mb-3">
                <label
                  style={{ fontFamily: "Poppins" }}
                  className="form-label text-capitalize"
                >
                  mobile number
                </label>
                <div className="align-self-stretch">
                  <input
                    type="number"
                    className="form-control"
                    readonly=""
                    disabled=""
                    value={profile && profile.phone}
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: "0.375rem",
                    }}
                  />
                </div>
              </div>
              {/* kyc section  */}
              <div className="pt-1" style={{ background: "white" }}>
                <div
                  className=""
                  style={{ border: "1px solid red", borderRadius: "5px" }}
                >
                  <Link
                    className={` w-100  p-3 d-block`}
                    to={
                      profile && profile.verified === `unverified`
                        ? `/Kyc2`
                        : `/Profile`
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className={` ${css.mytext} kyc-status-profile`}>
                        <p
                          className="mb-0 kyc-status-profiles"
                          style={{ fontFamily: "Poppins", fontSize: "12px" }}
                        >
                          KYC status
                        </p>
                        {profile && profile.verified === "unverified" ? (
                          <div>
                            Pending{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              width="20"
                              height="20"
                              fill="red"
                            >
                              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
                            </svg>
                          </div>
                        ) : profile && profile.verified === "pending" ? (
                          "In Process"
                        ) : (
                          "Verified ✅"
                        )}
                      </div>

                      {profile && profile.verified === "unverified" ? (
                        <div>
                          <button className="kyc-btn">Complete Here</button>
                        </div>
                      ) : profile && profile.verified === "pending" ? (
                        <div>
                          <button className="kyc-btn" disabled>
                            Verification in Process
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button className="kyc-btn-verified" disabled>
                            Verified Kyc Details
                          </button>
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-2" style={{ border: "1px solid #ccc" }}>
            <div
              className="card-header text-center"
              style={{
                fontWeight: "500",
                fontSize: "15px",
                letterSpacing: "0.3px",
                padding: "0.25rem 1.25rem"
              }}
            >
              Metrics
            </div>
            <div className="card-body p-2">
              <div className="gx-2 mb-1 g-0 row">
                <div className="col">
                  <div className="card" style={{ border: "1px solid #ccc" }}>
                    <div
                      className="card-header text-left"
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        letterSpacing: "0.3px",
                        padding: "6px",
                      }}
                    >
                      <div
                        className="d-flex gap-2"
                        style={{ alignItems: "center" }}
                      >
                        <picture>
                          <img
                            height="16px"
                            width="16px"
                            src={
                              process.env.PUBLIC_URL + "/Images/play-games.webp"
                            }
                            alt=""
                            className="snip-img"
                          />
                        </picture>
                        <div
                          className="m-0 card-title-metrics fs-12-responsive"
                          style={{ fontFamily: "Poppins", fontSize: "13px" }}
                        >
                          Games Played
                        </div>
                      </div>
                    </div>
                    <div
                      className="py-1 px-2 "
                      style={{
                        // padding: "6px",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      {total && total}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card" style={{ border: "1px solid #ccc" }}>
                    <div
                      className="card-header text-left"
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        letterSpacing: "0.3px",
                        padding: "6px",
                      }}
                    >
                      <div
                        className="d-flex gap-2"
                        style={{ alignItems: "center" }}
                      >
                        <picture>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="16"
                            height="16"
                            fill="currentColor"
                          >
                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                          </svg>
                        </picture>
                        <div
                          className="m-0 card-title-metrics"
                          style={{ fontFamily: "Poppins", fontSize: "13px" }}
                        >
                          Chips Won
                        </div>
                      </div>
                    </div>
                    <div
                      className="py-1 px-2 "
                      style={{
                        // padding: "6px",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      {profile && profile.wonAmount}
                    </div>
                  </div>
                </div>
              </div>
              <div className="gx-2 mb-2 g-0 row">
                <div className="col">
                  <div className="card" style={{ border: "1px solid #ccc" }}>
                    <div
                      className="card-header text-left"
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        letterSpacing: "0.3px",
                        padding: "6px",
                      }}
                    >
                      <div
                        className="d-flex gap-2"
                        style={{ alignItems: "center" }}
                      >
                        <picture>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="16"
                            height="16"
                            fill="currentColor"
                          >
                            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                          </svg>
                        </picture>
                        <div
                          className="m-0 card-title-metrics fs-11-responsive "
                          style={{ fontFamily: "Poppins", fontSize: "13px" }}
                        >
                          Referral Earning
                        </div>
                      </div>
                    </div>
                    <div
                      className="py-1 px-2 "
                      style={{
                        // padding: "6px",
                        color: "#000",
                        fontWeight: "600",
                      }}
                    >
                      {profile && profile.referralEarning}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card" style={{ border: "1px solid #ccc" }}>
                    <div
                      className="card-header text-left"
                      style={{
                        fontWeight: "500",
                        fontSize: "15px",
                        letterSpacing: "0.3px",
                        padding: "6px",
                      }}
                    >
                      <div
                        className="d-flex gap-2"
                        style={{ alignItems: "center" }}
                      >
                        <picture>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            width="16"
                            height="16"
                            fill="currentColor"
                          >
                            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                          </svg>
                        </picture>
                        <div
                          className="m-0 card-title-metrics"
                          style={{ fontFamily: "Poppins", fontSize: "13px" }}
                        >
                          Penalty
                        </div>
                      </div>
                    </div>
                    <div className="py-1 px-2 "
                    // style={{ padding: "6px" }}
                    >
                      <Penaltyprofile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-5">
            <Link onClick={(e) => logout(e)} to="/login">
              <button
                className="w-100"
                style={{
                  background: "none",
                  border: "1px solid #dc3545",
                  color: "#dc3545",
                  padding: "8px",
                  borderRadius: "8px",
                  fontFamily: "Poppins",
                }}
              >
                Logout
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>

      <div className={css.kyc_select} id="profileModal">
        <div className={css.overlay} />
        <div
          className={`${css.box}`}
          style={{
            bottom: "0px",
            position: "absolute",
          }}
        >
          <div className={css.bg_white}>
            <div className={`${css.header} ${css.cxy} ${css.flex_column}`}>
              <picture>
                <img
                  height="80px"
                  width="80px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
              </picture>
              <div className={`${css.header_text} mt-2`}>Choose Avatar</div>
            </div>
            <div className="mx-3 pb-3" style={{ paddingTop: "200px" }}>
              <div className="row justify-content-between col-10 mx-auto">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar1.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar2.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar3.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar4.png"}
                  alt=""
                />
              </div>
              <div className="row justify-content-between col-10 mx-auto mt-3">
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar5.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar6.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar7.png"}
                  alt=""
                />
                <img
                  height="50px"
                  width="50px"
                  src={process.env.PUBLIC_URL + "/Images/avatars/Avatar8.png"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile1;
