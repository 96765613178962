import React from 'react'

const Guidebtn = () => {
  return (
    <>


  <div
    className="offcanvas offcanvas-bottom"
    tabIndex="-1"
    id="offcanvasBottom"
    aria-labelledby="offcanvasBottomLabel"
    style={{height:"300px"}}
  >
    <div className="offcanvas-header bg-dark ">
      <h5 className="offcanvas-title fs-3 text-white" id="offcanvasBottomLabel" style={{fontFamily:"Poppins"}}>
        How To Play Games & Earn?
      </h5>
      <button
        type="button"
        className="btn-close bg-white  "
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        
    
        
      ></button>
    </div>
    <div className="offcanvas-body" style={{fontFamily:"Poppins"}}>Guide to Responsible Play
Sometimes, players may find it hard to recognize that their online play is getting out of control. A common reaction is to minimize, hide or deny such problems and the harm it could be causing. Some people will lie to themselves about how much money or time is being spent on online playing. Following are some of the best practices to help you play responsibly:

Play in moderation and only for entertainment. Do not play to make money or escape problems. Never chase your losses while playing. Set aside an entertainment budget. Keep track of the time and monitor the amount of money you spend. Use the Add Cash limit option to help control the amount you spend. Balance the time you spend on playing online games with other leisure activities.

Recognize whether you are not playing responsibly Do you do any of the following:

Do you spend more money and time than you intend to playing games? Do you feel guilty or ashamed while game playing habits? Do you try to win back your losses? Do you miss important things in life such as family time, work, leisure activities, appointments to play games? Do you think about your gameplay all day long? Do you have arguments with friends or family about you playing habits? Do you lie or steal to get money to play games? Do you have debts or struggled financially to play games? Has playing games negatively impacted your professional life? Do you have relationship problems like arguments, disagreements or loss of Connection with friends and family? Have you experienced an increase in stress, depression or panic attacks due to playing games?

Game Prudence Game Prudence is an independent body which aims to help players, playing on skill-gaming websites, in incorporating responsible gaming habits into their lifestyles. Game Prudence is a non-judgemental platform providing you with private, confidential and free of cost psychological counselling, wherein you can identify how healthy your gaming habits are as well as receive professional guidance from experts. All Game Prudence experts are certified by iGaming Academy. To Learn More..

Get Help from Game Prudence If you suspect that you are facing any of the issues mentioned above and as a result have not been playing responsibly, or if you know someone who is facing similar issues, kindly seek help immediately. You can get help by registering your request at Game Prudence.</div>
  </div>
  </>
  )
}

export default Guidebtn;