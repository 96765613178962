import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import "../css/landing.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { Link, NavLink, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
//import Rightcontainer from "../Components/Rightcontainer";
import Youtube from "../../assets/images/youtube.png";

import BetCard from "./BetCard";
import RunningCard from "./RunningCard";
import Header from "../Components/Header";
import Commission from "./Commission";
import CrowdCard from "./crowd-card";
import RunningBattleCrowd from "./running-battle-crowd";
import { create } from "@mui/material/styles/createTransitions";

// const STATIC_BET_AMOUNTS = [
//   50, 50, 350, 100, 3000, 50, 150, 900, 1500, 50, 100, 250, 500, 100, 50, 2500,
//   700, 50, 350, 1100, 650, 100, 50, 5000, 450, 250, 600, 1050, 1500, 100, 150,
//   350, 50, 550, 1100, 1200, 150, 1600, 450, 50, 50, 2000, 300, 2500, 100, 750,
//   350, 3000, 50, 1650, 11500, 850, 400, 500, 600, 50, 200, 7000, 10000, 1350,
//   100, 300, 950,
// ];

let STATIC_BET_AMOUNTS = [
  50, 50, 350, 100, 3000, 50, 150, 900, 1500, 50, 100, 250, 500, 100, 50, 2500,
  700, 50, 350, 1100, 650, 100, 50, 5000, 450, 250, 600, 1050, 1500, 100, 150,
  350, 50, 550, 1100, 1200, 150, 1600, 450, 50, 50, 2000, 300, 2500, 100, 750,
  350, 3000, 50, 1650, 850, 400, 500, 600, 50, 200, 1350, 100, 300, 950,
];

// Define the threshold amount
const threshold = 25000;

// Divide the array into two parts based on the threshold
const belowThreshold = STATIC_BET_AMOUNTS.filter(
  (amount) => amount < threshold
);
const aboveThreshold = STATIC_BET_AMOUNTS.filter(
  (amount) => amount >= threshold
);

// Print or use the results as needed
// console.log("Below threshold:", belowThreshold);
// console.log("Above threshold:", aboveThreshold);

//import { Alert } from "@mui/material";

export default function Homepage({ walletUpdate }) {
  // console.log("home page triggered....");
  //const history = useHistory();
  let userID = useRef();
  const isMounted = useRef(true);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const websocketURL = process.env.REACT_APP_SOCKET_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  /// user details start

  const [user, setUser] = useState();
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();
  const [staticBets, setStaticBets] = useState([]);
  const [staticRunningBets, setRunningStaticBets] = useState([]);

  const [userAllData, setUserAllData] = useState();
  const [randomName, setRandomName] = useState();
  const [isEffectCalled, setIsEffectCalled] = useState(false);

  const [isMode, setMode] = useState(
    localStorage.getItem("Mode") || "liteMode"
  );

  STATIC_BET_AMOUNTS = isMode === "liteMode" ? belowThreshold : aboveThreshold;

  useEffect(() => {
    setStaticBets(generateRandomBetSet(5)); // Initialize with 10 random bets
    setRunningStaticBets(generateRandomBetSet(5)); // Initialize with 10 random bets

    const interval = setInterval(() => {
      setStaticBets((prevStaticBets) => {
        const randomAmountIndex = Math.floor(
          Math.random() * STATIC_BET_AMOUNTS.length
        );

        // Decide whether to add or remove a card
        const addCard = Math.random() < 0.5; // 50% chance to add a card

        if (addCard) {
          // Add a new random bet to the beginning of the array
          return [
            generateRandomBet(STATIC_BET_AMOUNTS[randomAmountIndex]),
            ...prevStaticBets.slice(0, 5), // Slice to keep only 9 cards
          ];
        } else {
          // Remove the last bet from the array, if it's not empty
          if (prevStaticBets.length > 0) {
            return prevStaticBets.slice(0, 5); // Slice to keep only 9 cards
          } else {
            return prevStaticBets; // Return the unchanged state
          }
        }
      });

      setRunningStaticBets((prevRunningStaticBets) => {
        const randomAmountIndex = Math.floor(
          Math.random() * STATIC_BET_AMOUNTS.length
        );

        // Decide whether to add or remove a card
        const addCard = Math.random() < 0.5; // 50% chance to add a card

        if (addCard) {
          // Add a new random bet to the beginning of the array
          return [
            generateRandomBet(STATIC_BET_AMOUNTS[randomAmountIndex]),
            ...prevRunningStaticBets.slice(0, 5), // Slice to keep only 9 cards
          ];
        } else {
          // Remove the last bet from the array, if it's not empty
          if (prevRunningStaticBets.length > 0) {
            return prevRunningStaticBets.slice(0, 5); // Slice to keep only 9 cards
          } else {
            console.log("static bet length is zero");
            return prevRunningStaticBets; // Return the unchanged state
          }
        }
      });
    }, 1500);

    return () => clearInterval(interval);
  }, [randomName]);
  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++=
  // useState(() => {
  //   console.log("static bet changed");
  // }, [staticBets]);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        userID.current = res.data._id;
        setMount(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  /// user details end

  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );
  const [gameAmount, setGame_Ammount] = useState("");
  const [loading, setLoading] = useState(false);

  // console.log("Type of the game is :- ", game_type);

  const ChallengeCreate = (e) => {
    if (!gameAmount) {
      Swal.fire({
        title: "Game Amount",
        text: "Please enter game amount.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    } else if (isMode === "liteMode" && gameAmount > 25000) {
      Swal.fire({
        title: "Game Amount",
        text: "Please enter game amount 50 to 25000.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    // set bet timimng issue change:- start
    let ws_data = {
      id: userID.current,
      gameType: game_type,
      gameAmount: gameAmount,
    };
    console.log("Data sent to websocket is :- ", ws_data);
    setLoading(true);
    socket.emit("gameCreated", ws_data);
    setGame_Ammount("");
    // set bet timimng issue change:- end
  };

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  //const [ALL, setALL] = useState();
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);
  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `challange/all`, { headers })
      .then((res) => {
        if (res.data.message == "Game challenges not found") {
          return;
        }
        let owenedCreated = [],
          remainingGame = [];
        res.data.forEach(function (ele) {
          if (
            ele.createdBy._id === user &&
            (ele.status === "new" || ele.status === "requested")
          ) {
            owenedCreated.push(ele);
          } else {
            remainingGame.push(ele);
          }
        });
        console.log("Allgames() challenge API response", res.data);
        setCreated(owenedCreated);
        setallgame(remainingGame);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const runningGame = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `challange/running/all`, { headers })
      .then((res) => {
        console.log("runninggame() response is ", res);
        if (res?.data?.message == "Game challenges not found") {
          setOwnRunning([]);
          // setRunningGames([]);
          return;
        }
        let owenedRunning = [],
          remainingRunning = [];
        res.data.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id === userID.current ||
              ele.acceptedBy._id === userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      })
      .catch((e) => {
        console.log("errror", e);
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          //    setTimeout(() => {
          // //  history.push("/login")
          // }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    console.log("fetch data");
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    console.log(data);
    return setWebsiteSettings(data);
  };

  const fetchRandomName = async () => {
    console.log("fetch data");
    const response = await fetch(baseUrl + "randomUserName");
    const data = await response.json();
    console.log(data);
    return setRandomName(data);
  };

  useEffect(() => {
    fetchData();
    fetchRandomName();
  }, []);

  function generateRandomString(length) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function winamountFun(gameAmount) {
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 400) {
      profit =
        (gameAmount * parseInt(WebSitesettings?.adminPercentage50To400 || 5)) /
        100;
    } else if (gameAmount > 400) {
      profit =
        (gameAmount * parseInt(WebSitesettings?.adminPercentage400To || 5)) /
        100;
    }
    return gameAmount - profit;
  }

  function generateRandomBet(amount) {
    const winAmount = winamountFun(amount);
    return { amount, winAmount, playerName: generateRandomString(6) };
  }

  function generateSubArray(mainArray, subArrayLength) {
    // Check if the main array is shorter than the desired subarray length
    if (mainArray.length < subArrayLength) {
      throw new Error("Main array length is shorter than subarray length.");
    }

    // Generate random start index within valid range
    const startIndex = Math.floor(
      Math.random() * (mainArray.length - subArrayLength + 1)
    );

    // Calculate end index within valid range
    const endIndex = startIndex + subArrayLength;

    // Generate the subarray
    const subArray = mainArray.slice(startIndex, endIndex);

    return subArray;
  }

  function generateRandomBetSet(setLength) {
    const amounts = generateSubArray(STATIC_BET_AMOUNTS, setLength);
    const bets = amounts.map((amount) => {
      return generateRandomBet(amount);
    });
    return bets;
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };

    let socket = new WebSocket(websocketURL);

    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    function openFunc() {
      socket.onopen = () => {
        console.log("websocket is connected 👍");
        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          socket._socketListeners[event](data);
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });

      socket.listen("recieveGame", (data) => {
        console.log("receiveGameData", data);

        //set bet timeing issue change start
        if (data?.msg) {
          setLoading(false);
          if (data?.userId === userID.current) {
            // ToastError(data.msg);
            Swal.fire({
              title: `${data.msg}`,
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
          return;
        }
        setLoading(false);
        //set bet timeing issue change :- end
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id === userID.current &&
              (ele.status === "new" || ele.status === "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        // console.log('own',owenedCreated,'remiain',remainingGame);
        console.log("ownedCreated", owenedCreated);
        console.log("remainingGame", remainingGame);
        setCreated(owenedCreated);
        setallgame(remainingGame);
        // setLoading(false)
      });

      socket.listen("updateRunning", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("acceptor_seen", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("resultUpdateReq", (data) => {
        let owenedRunning = [],
          remainingRunning = [];
        data.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("startAcepptor", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        walletUpdate();
      });

      socket.listen("challengeAccepted", (data) => {
        // code change
        if (data?.msg) {
          if (data?.userId === userID.current) {
            // ToastError(data.msg);
            Swal.fire({
              title: `${data.msg}`,
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
        //code change
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateReject", (data) => {
        //start
        if (data?.msg) {
          if (data.userId === userID.current) {
            // ToastError(data.msg);
            Swal.fire({
              title: `${data.msg}`,
              text: "Please enter game amount.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        }
        //end
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("ongoingChallenge", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.createdBy && ele.acceptedBy)
            if (
              ele.createdBy._id == userID.current ||
              ele.acceptedBy._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      });

      socket.listen("updateDelete", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.createdBy)
            if (
              ele.createdBy._id == userID.current &&
              (ele.status == "new" || ele.status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      //changed here
      socket.listen("socketUpdate", (data) => {
        console.log("Update socket data is here and the data is :- ", data);
        Allgames();
        runningGame();
        if (ownRunning) {
          setTimeout(() => {
            Allgames();
            runningGame();
          }, 120000);
        }
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");

        if (isMounted.current) {
          clearTimeout(socket.pingTimeout);
          setSocket(undefined);

          let socket = new WebSocket(websocketURL);

          //socket = new WebSocket("ws://192.168.29.119:5001/server");
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {
        //  history.push("/login")
      }, 500);
    }
    role();
    if (mount) {
      Allgames();
      runningGame();
    }
    // if (ownRunning) {
    //   setTimeout(() => {
    //     Allgames();
    //     runningGame();
    //   }, 120000);
    // }
  }, [mount]);

  useEffect(() => {
    if (ownRunning) {
      setTimeout(() => {
        Allgames();
        runningGame();
      }, 120000);
    }
  }, []);
  //accept Challange

  const AcceptChallang = (id) => {
    let ws_data = {
      userId: userID.current,
      gameId: id,
    };

    socket.emit("acceptGame", ws_data);

    // const access_token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };
    // axios
    //   .put(
    //     baseUrl + `challange/accept/${id}`,
    //     {
    //       acceptedBy: headers,
    //       acceptorByCreatorAt: Date.now(),
    //     },
    //     {
    //       headers,
    //     }
    //   )
    //   .then((res) => {
    //     if (res.data.msg === "you have already enrolled") {
    //       Swal.fire({
    //         title: "You have already enrolled",
    //         icon: "warning",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //     if (res.data.msg === "Insufficient balance") {
    //       Swal.fire({
    //         title: "Insufficient balance",
    //         icon: "warning",
    //         confirmButtonText: "OK",
    //       });
    //     } else {
    //       Allgames(res.data);
    //       socket.emit("acceptGame");
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     if (e.response.status == 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //       setTimeout(() => {
    //         //  history.push("/login")
    //       }, 500);
    //     }
    //     if (e.response.status == 400 || e.response.status == 429) {
    //       Swal.fire({
    //         title: "Please refresh!",
    //         icon: "warning",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   });
  };

  //reject Game
  const RejectGame = (id) => {
    let ws_data = {
      userId: userID.current,
      gameId: id,
    };

    socket.emit("gameRejected", ws_data);
    // const access_token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };

    // axios
    //   .put(
    //     baseUrl + `challange/reject/${id}`,
    //     {
    //       acceptedBy: null,
    //       status: "new",
    //       acceptorByCreatorAt: null,
    //     },
    //     { headers }
    //   )
    //   .then((res) => {
    //     socket.emit("gameRejected");
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     if (e.response.status == 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //       setTimeout(() => {
    //         //  history.push("/login")
    //       }, 500);
    //     }
    //     if (e.response.status == 400 || e.response.status == 429) {
    //       Swal.fire({
    //         title: "Please refresh!",
    //         icon: "warning",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   });
  };

  //delete
  const deleteChallenge = (_id) => {
    socket.emit("deleteGame", _id);
    // const access_token = localStorage.getItem("token");
    // const headers = {
    //   Authorization: `Bearer ${access_token}`,
    // };

    // axios
    //   .delete(baseUrl + `challange/delete/${_id}`, { headers })
    //   .then((res) => {
    //     socket.emit("deleteGame", _id);
    //   })
    //   .catch((e) => {
    //     //console.log(e);
    //     if (e.response.status == 401) {
    //       localStorage.removeItem("token");
    //       localStorage.removeItem("token");
    //       window.location.reload();
    //       setTimeout(() => {
    //         //  history.push("/login")
    //       }, 500);
    //     }
    //     if (e.response.status == 400 || e.response.status == 429) {
    //       Swal.fire({
    //         title: "Please refresh!",
    //         icon: "warning",
    //         confirmButtonText: "OK",
    //       });
    //     }
    //   });
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/running/update/${_id}`,
        {
          acceptorSeen: true,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("game_seen");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
  };

  // const [roomCode, setRoomCode] = useState()

  const getPost = async (Id) => {
    // console.log("getPsot is triggered....");
    if (
      game_type === "Ludo Classics" ||
      game_type === "Ludo 1 Goti" ||
      game_type === "Ludo Ulta"
    ) {
      // console.log("getPsot is triggered....roomcode inside getPost");
      socket.emit("roomCode", { game_id: Id, status: "running" });
    } else if (game_type === "Ludo Popular") {
      // console.log("getPsot is triggered....popularcode inside getPost");
      socket.emit("popularroomCode", { game_id: Id, status: "running" });
    }
  };
  const [postsecond, setPostsecond] = useState([]);
  const [post, setPost] = useState([]);

  useEffect(() => {
    axios.get(baseUrl + "getmessage2").then((response) => {
      setPostsecond(response.data);
    });
    axios
      .get(baseUrl + "getmessage3")
      .then((response) => {
        // console.log("Message3 from admin is :- ", response);
        setPost(response.data);
        // console.log(setPost)
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (randomName && randomName.length > 0) {
      randomName.forEach((item) => {
        setStaticBets(generateRandomBetSet(10));
      });
    }
  }, [randomName]);

  // console.log("setCreated() created bet :-", created);
  // console.log("setAllGame() allgame bet :-", allgame);
  // console.log("setOwnrunning() Own running bat:-", ownRunning);
  // console.log("setRunningGame() running bats only:- ", runningGames);

  return (
    <>
      <div
        className="leftContainer "
        style={{ minHeight: "100vh", paddingTop: "60px" }}
      >
       
        <div
          className="leftContainer"
          style={{
            height: "38px",
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: "99999",
          }}
        >
          {postsecond.length > 0 ? (
            <marquee
              className="marqueebox-bg w-100"
              style={{
                textAlign: "left",
              }}
              direction="left"
              scrollamount="4"
            >
              {postsecond.map((ele) => (
                <p
                  className="message-text mb-0 p-2 text-dark"
                  style={{
                    fontWeight: "900",
                    color: "#664D03 !important",
                    fontSize: "14.4px",
                    fontFamily: "Poppins",
                  }}
                >
                  {ele.message}
                </p>
              ))}
            </marquee>
          ) : null}
        </div>
        <div className="landing-header">
          <Header user={userAllData} />
        </div>
        
        <div className="mt-5 p-3">
          <div className={css.mainArea} style={{ minHeight: "100vh" }}>
            
             {post.length > 0 ? (
                          <div
                            className="messagebox-bg w-100"
                            style={{
                              borderRadius: "5px",
                              // margin: "8px",
                              textAlign: "left",
                            }}
                          >
                            <p>
                              {post.map((element) => (
                                <p
                                  className=" message-text mb-0 p-3"
                                  style={{
                                    fontWeight: "900",
                                    color: "#664D03",
                                    fontSize: "14.4px",
                                    textAlign: "justify",
                                  }}
                                >
                                  {element.message}
                                </p>
                              ))}
                            </p>
                          </div>
                        ) : null}

            {/* <div className="card rounded-pill border mb-4">
            <div className="d-flex justify-content-between align-items-center">
            <div className="text-center ml-3">
              <label className="form-check-label font-weight-bold" htmlFor="themeSwitch" id="themeSwitchLabel">
                    {isMode === "liteMode" ? 'Lite Mode' : 'Rich Mode'}
              </label>
            </div>
            <div className="text-center">
              <label className="form-check-label font-weight-bold" htmlFor="themeSwitch" id="themeSwitchLabel">
                    {isMode === "liteMode" ? '50 to 550' : '550 to 25000'}
              </label>
            </div>
            <button onClick={() => toggleTheme(isMode === "richMode" ? 'liteMode' : 'richMode' )} className="btn btn-danger text-white rounded-pill">{isMode === "liteMode" ? '👉 Switch to Rich Mode' : '👉 Switch to Lite Mode' }</button>            
            </div>
          </div> */}

            {/* <div className="mb-4 mt-2">
            <div className="d-flex justify-content-between align-items-center">
                <button onClick={() => toggleTheme('liteMode')} className={`btn ${isMode === "liteMode" ? "btn-danger text-white" : "btn-secondary text-black"} py-2  w-100 mr-2`}>Lite Mode (50 to 550)</button>            
                <button onClick={() => toggleTheme("richMode")} className={`btn ${isMode === "liteMode" ? "btn-secondary text-black" : "btn-danger text-white"} py-2  w-100 mr-2`}>Rich Mode (550 to 25000)</button>            
            </div>
          </div> */}

            <div className="tab-content" id="pills-tabContent">
              {/* Lite mode content section */}

              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex="0"
              >
                <div
                  className=" d-flex my-2 w-100  rounded-2 "
                  style={{
                    alignItems: "center",
                    border: "1px solid #ccc",
                    paddingRight: "2px",
                    paddingTop: "1px",
                    paddingBottom: "1px",
                  }}
                >
                  <div className="w-100">
                    <input
                      style={{
                        width: "100%",
                        border: "0px",
                        background: "none",
                      }}
                      className={`${css.formControl} set-input`}
                      type="tel"
                      placeholder="Enter amount"
                      value={gameAmount}
                      onChange={(e) => setGame_Ammount(e.target.value)}
                    />
                  </div>

                  <div className="set ml-1 ">
                    {" "}
                    <button
                      className={`  position-static px-3 rounded-2 `}
                      style={{
                        margin: "20px !important",
                        backgroundColor: "#123441",
                        height: "40px",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        ChallengeCreate();
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "15px",
                          fontWeight: "700",
                          fontFamily: "Poppins",
                          letterSpacing: "1px",
                        }}
                      >
                        {loading ? (
                          <>
                            {" "}
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          "Set"
                        )}
                      </span>
                    </button>
                  </div>
                </div>
                <div className={css.dividerX}></div>
                <div className=" py-3">
                  <div className="mb-3">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/Homepage/battleIcon.png"
                      }
                      alt=""
                      width="20px"
                    />
                    <span
                      style={{
                        fontSize: "20px",
                        color: "#fe3347",
                        fontFamily: "Poppins",
                      }}
                      className={`ml-2 ${css.gamesSectionTitle}`}
                    >
                      Open Battles
                    </span>
                    <span
                      className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold`}
                      style={{ right: "1.5rem", color: "#00D25B" }}
                    >
                      <span style={{ fontSize: "13px" }}> Rules</span>
                      <NavLink to="/Rules">
                        <img
                          className="ml-2"
                          style={{ height: "20px", width: "20px" }}
                          src={
                            process.env.PUBLIC_URL + "/Images/Homepage/info.png"
                          }
                          alt=""
                        />
                      </NavLink>
                    </span>
                  </div>

                  {created &&
                    created?.map(
                      (allgame) =>
                        allgame.gameType == game_type && (
                          <BetCard
                            key={allgame._id}
                            allgame={allgame}
                            user={user}
                            deleteChallenge={deleteChallenge}
                            getPost={getPost}
                            RejectGame={RejectGame}
                            winamountFun={winamountFun}
                            AcceptChallang={AcceptChallang}
                            updateChallenge={updateChallenge}
                          />
                        )
                    )}
                  {allgame &&
                    allgame?.map(
                      (allgame) =>
                        (allgame.status == "new" ||
                         (allgame.status == "conflict" &&
                            allgame.acceptorSeen == false &&
                            user === allgame.acceptedBy._id) ||
                          (allgame.status == "pending" &&
                            user == allgame.acceptedBy._id &&
                            allgame.acceptorSeen == false) ||
                          (allgame.status == "requested" &&
                            (user == allgame.createdBy._id ||
                              user == allgame.acceptedBy._id)) ||
                          (allgame.status == "running" &&
                            user == allgame.acceptedBy._id &&
                            allgame.acceptorSeen == false)) &&
                        allgame.gameType == game_type && (
                          <BetCard
                            key={allgame._id}
                            allgame={allgame}
                            user={user}
                            deleteChallenge={deleteChallenge}
                            getPost={getPost}
                            RejectGame={RejectGame}
                            winamountFun={winamountFun}
                            AcceptChallang={AcceptChallang}
                            updateChallenge={updateChallenge}
                          />
                        )
                    )}
                  {/* {staticBets &&
                    staticBets.map((bet, index) => (
                      <CrowdCard
                        key={index}
                        bet={bet}
                        onPlayClicked={() => {
                          setStaticBets((currentState) => {
                            const copyArray = JSON.parse(
                              JSON.stringify(currentState)
                            );
                            copyArray.splice(index, 1);
                            return copyArray;
                          });
                        }}
                      />
                    ))} */}
                </div>
                <div
                  style={{ background: "red", width: "100%", height: "3px" }}
                ></div>
                <div className=" py-3 mb-5">
                  <div className="mb-2">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/Homepage/battleIcon.png"
                      }
                      alt=""
                      width="20px"
                    />
                    <span
                      style={{
                        fontSize: "20px",
                        color: "red",
                        fontFamily: "Poppins",
                      }}
                      className={`ml-2 ${css.gamesSectionTitle}`}
                    >
                      Running Battles
                    </span>
                  </div>
                  {ownRunning &&
                    ownRunning.map((runnig) => {
                      const isUserAccepted = user === runnig.acceptedBy._id;
                      const isUserCreator = user === runnig.createdBy._id;
                      const isUserConflicted =
                        (isUserAccepted && runnig.status === "conflict") ||
                        (isUserCreator && runnig.status === "conflict");

                      // change
                      if (
                        (user == runnig.acceptedBy._id &&
                          runnig.acceptorSeen == false)
                      ) {
                        return;
                      }

                      if (user === runnig?.acceptedBy._id) {
                        // console.log("in acceptor block");
                        if (
                          (runnig?.status === "running" &&
                            runnig?.acceptorSeen) ||
                          runnig.status === "pending" ||
                          runnig.status === "conflict" ||
                          isUserConflicted
                        ) {
                          // console.log("running game", runnig);
                          if (runnig.gameType === game_type) {
                            return (
                              <RunningCard
                                key={runnig._id}
                                runnig={runnig}
                                user={user}
                                winamountFun={winamountFun}
                              />
                            );
                          }
                        }
                      } else {
                        if (
                          (runnig.status === "running" &&
                            user === runnig.createdBy._id) ||
                          runnig.status === "pending" ||
                          runnig.status === "conflict" ||
                          isUserConflicted
                        ) {
                          if (runnig.gameType === game_type) {
                            return (
                              <RunningCard
                                key={runnig._id}
                                runnig={runnig}
                                user={user}
                                winamountFun={winamountFun}
                              />
                            );
                          }
                        }
                      }
                    })}

                  {runningGames &&
                    runningGames?.map((runnig) => {
                      if (
                        (user == runnig.acceptedBy._id ||
                        user == runnig.createdBy._id
                          ? user == runnig.acceptedBy._id
                            ? (runnig.status === "running" &&
                                user == runnig.acceptedBy._id &&
                                runnig.acceptorSeen == true) ||
                              (runnig.status === "pending" &&
                                runnig.acceptorStatus == null)
                            : (runnig.status === "running" &&
                                user == runnig.createdBy._id) ||
                              (runnig.status === "pending" &&
                                runnig.creatorStatus == null)
                          : runnig.status === "running" ||
                            runnig.status === "pending") &&
                        runnig.gameType == game_type
                      )
                        return (
                          <RunningCard
                            key={runnig._id}
                            runnig={runnig}
                            user={user}
                            winamountFun={winamountFun}
                          />
                        );
                    })}
                  {staticRunningBets &&
                    staticRunningBets.map((bet, index) => (
                      <RunningBattleCrowd key={index} bet={bet} />
                    ))}
                </div>
              </div>

              {/* Rich mode content section*/}
            </div>
          </div>
        </div>
              <section className="footer pt-4">
                <div
                  className="position-fixed d-flex align-items-end justify-content-end hstack gap-2"
                  style={{
                    alignItems: "center",
                    zIndex: "10",
                    bottom: "60px",
                    left: "15px",
                    right: "20px",
                    maxWidth: "440px",
                  }}
                >
                  <Link
                    className=" d-flex shadow align-items-center justify-content-center bg-success"
                    to="/contact-us"
                    style={{
                      padding: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="3em"
                      height="3em"
                      viewBox="0 0 256 258"
                    >
                      <defs>
                        <linearGradient
                          id="logosWhatsappIcon0"
                          x1="50%"
                          x2="50%"
                          y1="100%"
                          y2="0%"
                        >
                          <stop offset="0%" stop-color="#1faf38" />
                          <stop offset="100%" stop-color="#60d669" />
                        </linearGradient>
                        <linearGradient
                          id="logosWhatsappIcon1"
                          x1="50%"
                          x2="50%"
                          y1="100%"
                          y2="0%"
                        >
                          <stop offset="0%" stop-color="#f9f9f9" />
                          <stop offset="100%" stop-color="#fff" />
                        </linearGradient>
                      </defs>
                      <path
                        fill="url(#logosWhatsappIcon0)"
                        d="M5.463 127.456c-.006 21.677 5.658 42.843 16.428 61.499L4.433 252.697l65.232-17.104a123 123 0 0 0 58.8 14.97h.054c67.815 0 123.018-55.183 123.047-123.01c.013-32.867-12.775-63.773-36.009-87.025c-23.23-23.25-54.125-36.061-87.043-36.076c-67.823 0-123.022 55.18-123.05 123.004"
                      />
                      <path
                        fill="url(#logosWhatsappIcon1)"
                        d="M1.07 127.416c-.007 22.457 5.86 44.38 17.014 63.704L0 257.147l67.571-17.717c18.618 10.151 39.58 15.503 60.91 15.511h.055c70.248 0 127.434-57.168 127.464-127.423c.012-34.048-13.236-66.065-37.3-90.15C194.633 13.286 162.633.014 128.536 0C58.276 0 1.099 57.16 1.071 127.416m40.24 60.376l-2.523-4.005c-10.606-16.864-16.204-36.352-16.196-56.363C22.614 69.029 70.138 21.52 128.576 21.52c28.3.012 54.896 11.044 74.9 31.06c20.003 20.018 31.01 46.628 31.003 74.93c-.026 58.395-47.551 105.91-105.943 105.91h-.042c-19.013-.01-37.66-5.116-53.922-14.765l-3.87-2.295l-40.098 10.513z"
                      />
                      <path
                        fill="#fff"
                        d="M96.678 74.148c-2.386-5.303-4.897-5.41-7.166-5.503c-1.858-.08-3.982-.074-6.104-.074c-2.124 0-5.575.799-8.492 3.984c-2.92 3.188-11.148 10.892-11.148 26.561s11.413 30.813 13.004 32.94c1.593 2.123 22.033 35.307 54.405 48.073c26.904 10.609 32.379 8.499 38.218 7.967c5.84-.53 18.844-7.702 21.497-15.139c2.655-7.436 2.655-13.81 1.859-15.142c-.796-1.327-2.92-2.124-6.105-3.716s-18.844-9.298-21.763-10.361c-2.92-1.062-5.043-1.592-7.167 1.597c-2.124 3.184-8.223 10.356-10.082 12.48c-1.857 2.129-3.716 2.394-6.9.801c-3.187-1.598-13.444-4.957-25.613-15.806c-9.468-8.442-15.86-18.867-17.718-22.056c-1.858-3.184-.199-4.91 1.398-6.497c1.431-1.427 3.186-3.719 4.78-5.578c1.588-1.86 2.118-3.187 3.18-5.311c1.063-2.126.531-3.986-.264-5.579c-.798-1.593-6.987-17.343-9.819-23.64"
                      />
                    </svg>
                  </Link>
                </div>
              </section>
      </div>
    </>
  );
}
