import React from "react";
import css from "../../Modulecss/Home.module.css";
import avtar1 from "../../Avtar/Avatar1.png";
import avtar2 from "../../Avtar/Avatar2.png";
import avtar3 from "../../Avtar/Avatar3.png";
import avtar4 from "../../Avtar/Avatar4.png";
import rupee_icon from "../global-rupeeIcon.png";

function generateRandomString(length) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function RunningBattleCrowd({ bet }) {
  const avatars = [avtar1, avtar2, avtar3, avtar4];

  return (
    <>
      {/* <div className="d-flex justify-content-end ">
        <label className="MuiFormControlLabel-root results_btn_view ">
          <span
            className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary"
            aria-disabled="false"
            root="[object Object]"
          >
            <span className="MuiTouchRipple-root" />
          </span>
          <span
            className="MuiTypography-root w-100  MuiFormControlLabel-label MuiTypography-body1"
            style={{ marginTop: 3 }}
            
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
          >
            <p className="mb-0 view-btn">View</p>
          </span>
        </label>
       
      </div> */}

      <div
        className={`${css.betCard} mt-2 p-2`}
        style={{ border: "1px solid #00209d" }}
      >
        <div className="d-flex">
          <span
            className={`${css.betCardTitle} playing-responsive d-flex align-items-center `}
            style={{ fontFamily: "Poppins", fontSize: "13px" }}
          >
            Playing For
            <img className="mx-1" src={rupee_icon} alt="" width="21px" />
            {bet.amount}
          </span>

          <div
            className={`${css.betCardTitle} d-flex align-items-center `}
            style={{ fontFamily: "Poppins", fontSize: "15px" }}
          >
            <span
              className="ml-auto"
              style={{ fontFamily: "Poppins", fontSize: "13px" }}
            >
              Prize
              <img className="mx-1" src={rupee_icon} alt="" width="21px" />
              {bet.amount + bet.winAmount}
            </span>
          </div>
        </div>
        <div className="py-1 row">
          <div className="pr-3 text-center col-5">
            {/* {console.log("https://nightking.com/user.png")} */}
            <div className="pl-2">
              {/* {runnig.createdBy.avatar ? (
              <img
                src={
                  baseUrl + `${runnig.createdBy && runnig.createdBy.avatar}`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = { getRandomAvatar };
                }}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            ) : (
              <img
                src={getRandomAvatar()}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            )} */}
            </div>
            <div style={{ textAlign: "center" }}>
              <span className={css.betCard_playerName}>
                {generateRandomString(6)}
              </span>
            </div>
          </div>
          <div className="pr-3 text-center col-2 cxy">
          <div class="verselogo" style={{textTransform: "uppercase" , fontWeight: 500 , fontSize: "12px", color: "black"}}>
                                 <div class="vslogo">v/s</div>
                            </div>
          </div>
          <div className="text-center col-5">
            <div className="pl-2">
              {/* {console.log(getRandomAvatar(),'getRandomAvatar')} */}
              {/* {console.log(runnig.acceptedBy)} */}

              {/* {runnig?.acceptedBy.avatar ? 
            (
              <img
                src={
                  baseUrl + `${runnig.acceptedBy && runnig.acceptedBy.avatar}`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = getRandomAvatar();
                }}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            ) : (
              <img
                src={getRandomAvatar()}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            )} */}
            </div>
            <div style={{ textAlign: "center" }}>
              <span className={css.betCard_playerName}>
                {generateRandomString(6)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
