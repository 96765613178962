import React from 'react'
import { useState, useEffect } from 'react';

const Rightcontainer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
    const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
    const nodeMode = process.env.NODE_ENV;
    
    if(nodeMode==="development"){
    var baseUrl = beckendLocalApiUrl;
    }else{
     baseUrl = beckendLiveApiUrl
    }

  const [WebSitesettings, setWebsiteSettings] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  }
  document.title=(WebSitesettings)?WebSitesettings.webTitle:'Skill based game';
  //console.log(WebSitesettings);
  useEffect(() => {
    fetchData();
  },[])

  return (
    <div>
         <div className='rightContainer'>
                <div className="rcBanner flex-center ">
                    <picture className="rcBanner-img-container animate__bounce infinite " style={{textAlign:"center"}}>
                        <img src="/Images/LandingPage_img/Header_profile.jpg" alt="nothing"/>
                    </picture>
                    <div className="rcBanner-text " style={{fontWeight:'300'}}> PLAY LUDO & <span className="rcBanner-text-bold" style={{fontWeight:'900', lineHeight:"48px",Color:"#212529",fontSize:"2rem"}}>Win Real Cash !</span></div>
                    <div className="rcBanner-footer fs-3">For best experience, open&nbsp;
                    <a href="/" style={{
                        color: 'rgb(44, 44, 44)',
                        fontWeight: 500, textDecoration: 'none',
                        borderBottom:"2px solid #427CFF",fontFamily:"Poppins"
                    }}>{(WebSitesettings)?WebSitesettings.websiteName:''}</a>&nbsp;on&nbsp;&nbsp;chrome mobile</div>
                </div>
            </div>
    </div>
  )
}

export default Rightcontainer