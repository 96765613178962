import React from "react";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import Kyc from "./Kyc";

import "../css/landing.css";

const Support1 = () => {
  return (
    <div>
      <div
        className="leftContainer "
        style={{ height: "100vh", paddingTop: "85px" }}
      >
        {/* <div className="m-2 mt-3">
          <Kyc />
        </div> */}
        <div className="cxy flex-column ">
          <img
            src={process.env.PUBLIC_URL + "/Images/contact_us.webp"}
            width="330px"
            alt="/"
          />

          <div className="row justify-content-center w-100 p-2">
            <a className="cxy flex-column" href="https://wa.me/+917425924145">
              <button type="button" className=" text-white support-btn">
                <i className="fa-brands fa-whatsapp pe-2 "></i>Connect On
                Whatsapp
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support1;
