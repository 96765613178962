import React, { useState, useEffect } from "react";
import "../css/gamehistory.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import chips_add from "../../assets/images/history_icons/chips-add.png";

const Bonus = ({ data1, isPropData }) => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  //eslint-disable-next-line
  const [user, setUser] = useState();
  //use for pagination..
  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios.get(baseUrl + `me`, { headers }).then((res) => {
      setUser(res.data);

      //Allgames(res.data._id)
      Allgames(res.data._id, pageNumber, limit);
      // window.location.reload()
    });
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",

      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    if (isPropData) {
      console.log("data is from props....at bonus.....");
      setGame(data1);
    } else {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      await axios
        .get(
          baseUrl + `temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`,
          {
            headers,
          }
        )
        .then((res) => {
          console.log("data is from api....at bonus.....");
          setGame(res.data.ress);

          setNumberOfPages(res.data.totalPages);
        });
    }
  };

  useEffect(() => {
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit, data1]);

  return (
    <div>
      <div
        className="leftContainer"
        style={{ height: "100%", marginBottom: "100px" }}
      >
        {/* pagination */}

        {/* <div className="pt-5">
          <div className="mt-4">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div> */}

        {/* game-cards */}
        {cardData &&
          cardData
            .filter(
              (card) => card.reqType === "bonus" && card.status !== "FAILED"
            )
            .map((card) => {
              var titleMsg = "Bonus added";
              var signIcon = <div color="#198754">(+)</div>;
              return (
                // <div
                //   className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
                // >
                //   {/* map the cardData */}
                //   <div className={`${css.center_xy} ${css.list_date} mx-3`}>

                //   </div>
                //   <div className={`${css.list_divider_y}`} />
                //   <div className={`mx-3 d-flex ${css.list_body}`}>
                //     <div className="d-flex align-items-center">
                //       <picture className="mr-5"></picture>
                //     </div>

                //     <div className="d-flex flex-column font-8">
                //       <div>
                //
                //       </div>
                //       <div className={`${css.games_section_headline}`}>
                //
                //       </div>
                //     </div>
                //   </div>

                //   <div className="right-0 d-flex align-items-end pr-3 flex-column">
                //     <div className="d-flex float-right font-8">
                //
                //
                //       <span className="pl-1"></span>
                //     </div>
                //
                //     <div
                //       className="games-section-headline"
                //       style={{ fontSize: "0.6em" }}
                //     ></div>
                //   </div>
                // </div>
                <div
                  className="px-1 py-2 w-100"
                  style={{ borderBottom: "1px solid #ccc" }}
                >
                  <div className="d-flex align-items-stretch w-100">
                    <div
                      className=" d-flex flex-column align-items-start justify-content-center border-end "
                      // style="width: 80px;"
                      style={{ width: "80px" }}
                    >
                      <span>
                        <img src={chips_add} alt="/" />
                      </span>
                      <span
                        className="text-start"
                        style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                      >
                        {dateFormat(card.createdAt).split(",")[0]}
                      </span>
                      <span
                        className="text-start"
                        style={{ fontSize: "0.8rem", fontFamily: "Poppins" }}
                      >
                        {dateFormat(card.createdAt).split(",")[1]}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-stretch justify-content-center flex-1 w-100 px-2">
                      <div className="d-flex align-items-stretch justify-content-between">
                        <div className="d-flex flex-column">
                          <span
                            className="fw-semibold text-capitalize text-start"
                            // style="font-size: 0.9rem;"
                            style={{
                              fontSize: "0.8rem",
                              fontFamily: "Poppins",
                            }}
                          >
                            {titleMsg}
                          </span>
                        </div>
                        <div className="d-flex  align-items-end justify-content-center gap-1 ">
                          <span
                            className=" text-end fw-bold my-1"
                            style={{
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              color: "#198754",
                            }}
                          >
                            {signIcon}
                          </span>
                          <span
                            className=" text-end fw-bold my-1"
                            style={{
                              fontSize: "0.9rem",
                              fontWeight: "bold",
                              color: "#198754",
                            }}
                          >
                            {card.amount}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span
                          className="text-start text-capitalize"
                          // style="font-size: 0.7rem;"
                          style={{ fontSize: "0.7rem", fontFamily: "Poppins" }}
                        >
                          {card.reqType === "bonus" && (
                            <div>status: Bonus by admin</div>
                          )}
                        </span>
                        <span
                          className="text-start"
                          style={{ fontSize: "0.7rem", fontFamily: "Poppins" }}
                        >
                          {card.closingBalance && (
                            <div>Closing Balance :{card.closingBalance}</div>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        {/* pagination */}
        {/* <div className="">
          <div className="mt-5">
            <ReactPaginate
              previousLabel={"<< Previous"}
              nextLabel={"Next >>"}
              breakLabel={"..."}
              pageCount={numberOfPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={
                "pagination justify-content-center custom-pagination-container"
              }
              pageClassName={"page-item custom-page-item"}
              pageLinkClassName={"page-link custom-page-link"}
              previousClassName={"page-item custom-previous-item"}
              previousLinkClassName={"page-link custom-previous-link"}
              nextClassName={"page-item custom-next-item"}
              nextLinkClassName={"page-link custom-next-link"}
              breakClassName={"page-item custom-break-item"}
              breakLinkClassName={"page-link custom-break-link"}
              activeClassName={"active custom-active"}
            />
          </div>
        </div> */}

        {(!cardData ||
          cardData.length === 0 ||
          cardData.every((card) => card.reqType !== "bonus")) && (
          <div className="text-center">
            <img
              src={process.env.PUBLIC_URL + "/Images/refer_null.png"}
              alt="no data"
              className="img-fluid"
              style={{ marginTop: "25%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Bonus;
