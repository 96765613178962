import { React, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import css from "../Modulecss/Home.module.css";

import avtar1 from "../Avtar/Avatar1.png";
import avtar2 from "../Avtar/Avatar2.png";
import avtar3 from "../Avtar/Avatar3.png";
import avtar4 from "../Avtar/Avatar4.png";
import rupee_icon from "./global-rupeeIcon.png";
import userimage from "../Avtar/user.png";
import axios from "axios";

export default function RunningCard({
  runnig,
  user,
  winamountFun,
  game_type,
  props,
}) {
  const avatars = [avtar1, avtar2, avtar3, avtar4];
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  function getRandomAvatar() {
    const randomIndex = Math.floor(Math.random() * avatars.length);
    return avatars[randomIndex];
  }

  const randomAvatar = getRandomAvatar();
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);

  const viewClick = () => {
    if (runnig.status == "conflict") {
      setShowModal(true);
    } else {
      // history.push(`/viewgame1/${runnig._id}`, {
      //   prevPath: window.location.pathname,
      // });
    }
  };

  const handleContactSupportClick = () => {
    const targetRoute = "/contact-us";

    history.push(targetRoute);

    window.location.reload();
  };

  console.log("runnig object:", runnig);

  return (
    <>
      {/* <div className="d-flex justify-content-end ">
        <label className="MuiFormControlLabel-root results_btn_view ">
          <span
            className="MuiButtonBase-root MuiIconButton-root jss1 MuiRadio-root MuiRadio-colorSecondary MuiIconButton-colorSecondary"
            aria-disabled="false"
            root="[object Object]"
          >
            <span className="MuiTouchRipple-root" />
          </span>
          <span
            className="MuiTypography-root w-100  MuiFormControlLabel-label MuiTypography-body1"
            style={{ marginTop: 3 }}
            
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
          >
            <p className="mb-0 view-btn">View</p>
          </span>
        </label>
       
      </div> */}

      <div
        className={`${css.betCard} mt-2 p-2`}
        style={{ border: "1px solid #00209d" }}
      >
        <div className="d-flex">
          <span
            className={`${css.betCardTitle} playing-responsive d-flex align-items-center `}
            style={{ fontFamily: "Poppins", fontSize: "13px" }}
          >
            Playing For
            <img className="mx-1" src={rupee_icon} alt="" width="21px" />
            {runnig.gameAmount}
          </span>

          {(user === runnig.acceptedBy._id &&
            (runnig.status === "pending" || runnig.status === "conflict") &&
            runnig.acceptorStatus === null) ||
          (user === runnig.createdBy._id &&
            (runnig.status === "pending" || runnig.status === "conflict") &&
            runnig.creatorStatus === null) ||
          (user === runnig.acceptedBy._id &&
            runnig.creatorStatus === null &&
            runnig.acceptorStatus === null) ||
          (user === runnig.createdBy._id &&
            runnig.creatorStatus === null &&
            runnig.acceptorStatus === null) ? (
            <Link
              className={`${css.bgSecondary} ${css.playButton} ${css.cxy} position-relative m-2 mx-1 bg-success`}
              style={{
                right: "0px",
                top: "-6px",
                padding: "10px 17px",
              }}
              to={{
                pathname: `/viewgame1/${runnig._id}`,
                state: { prevPath: window.location.pathname },
              }}
            >
              View
            </Link>
          ) : (user === runnig.acceptedBy._id ||
              user === runnig.createdBy._id) &&
            (runnig.status === "conflict" || runnig.status === "pending") ? (
            <span
              className={` ${css.bgSecondary} ${css.playButton} ${css.cxy} position-relative m-2 mx-1 bg-danger`}
              style={{
                right: "0px",
                top: "-6px",
                padding: "10px 17px",
              }}
              data-bs-toggle="modal"
              data-bs-target={`#exampleModal1${runnig._id}`}
              onClick={viewClick}
            >
              <p className="mb-0 view-btn">View</p>
            </span>
          ) : null}

          <>
            <div
              className="modal fade"
              id={`exampleModal1${runnig._id}`}
              tabIndex={-1}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog m-0"
                style={{ position: "fixed", bottom: "0" }}
                yle
              >
                <div
                  className="modal-content rounded-top-4"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <div className="modal-header border-0">
                    <div>
                      <h1
                        className="modal-title view-code fs-5"
                        id="exampleModalLabel"
                      >
                        Room Code :
                        <span className="view-span"> {runnig.roomCode}</span>
                      </h1>

                      <h1
                        className="modal-title view-code fs-5"
                        id="exampleModalLabel"
                      >
                        Your Result :
                        <span className="view-span">
                          {user === runnig.createdBy._id && (
                            <span> {runnig.creatorStatus}</span>
                          )}

                          {user === runnig.acceptedBy._id && (
                            <span> {runnig.acceptorStatus}</span>
                          )}
                        </span>
                      </h1>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <div className="">
                      <div
                        className="shadow-view py-3 px-3"
                        style={{ border: "1px solid #ccc" }}
                      >
                        <div className="d-flex justify-content-between ">
                          <div className="d-flex gap-4 align-items-center ">
                            {runnig.createdBy.avatar ? (
                              <img
                                src={
                                  baseUrl +
                                  `${
                                    runnig.createdBy &&
                                    runnig.createdBy.avatar
                                  }`
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = { getRandomAvatar };
                                }}
                                alt=""
                                width="25px"
                                height="25px"
                                style={{
                                  borderTopLeftRadius: "50%",
                                  borderTopRightRadius: "50%",
                                  borderBottomRightRadius: "50%",
                                  borderBottomLeftRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                src={getRandomAvatar()}
                                alt=""
                                width="25px"
                                height="25px"
                                style={{
                                  borderTopLeftRadius: "50%",
                                  borderTopRightRadius: "50%",
                                  borderBottomRightRadius: "50%",
                                  borderBottomLeftRadius: "50%",
                                }}
                              />
                            )}
                            <h6 className="view-number">
                              {runnig.createdBy.name}
                            </h6>
                          </div>
                          <div className="d-block align-items-center gap-1  ">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/Images/Homepage/versus_new.png"
                              }
                              alt=""
                              width="45px"
                              height="45px"
                            />
                            <h6 className="view-number text-success">
                              {" "}
                              ₹{runnig.gameAmount}
                            </h6>
                          </div>
                          <div className="d-flex align-items-center gap-4  ">
                            <h6 className="view-number">
                              {runnig.acceptedBy.name}
                            </h6>
                            <div className="d-flex align-items-center justify-content-end">
                              {runnig?.acceptedBy.avatar ? (
                                <img
                                  src={
                                    baseUrl +
                                    `${
                                      runnig.acceptedBy &&
                                      runnig.acceptedBy.avatar
                                    }`
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = getRandomAvatar();
                                  }}
                                  alt=""
                                  width="25px"
                                  height="25px"
                                  style={{
                                    borderTopLeftRadius: "50%",
                                    borderTopRightRadius: "50%",
                                    borderBottomRightRadius: "50%",
                                    borderBottomLeftRadius: "50%",
                                  }}
                                />
                              ) : (
                                <img
                                  src={getRandomAvatar()}
                                  alt=""
                                  width="25px"
                                  height="25px"
                                  style={{
                                    borderTopLeftRadius: "50%",
                                    borderTopRightRadius: "50%",
                                    borderBottomRightRadius: "50%",
                                    borderBottomLeftRadius: "50%",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="pb-3">
                      {/* <div
                        className=" p-3"
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                      ></div> */}
                    </div>
                    <p style={{ borderBottom: "1px solid #ccc" }}></p>
                    <div>
                      <p
                        className="text-center your-game "
                        style={{
                          fontFamily: "Poppins",
                          color: "#000",
                          fontWeight: "500",
                          fontSize: "13px",
                        }}
                      >
                        Your Game Result is Successfully Posted. Please allow us
                        2-5 minutes to review & update your game. If you have
                        Posted Wrong Result or Screenshot, Kindly{" "}
                        <Link
                          to="/contact-us"
                          style={{ fontFamily: "Poppins" }}
                          onClick={handleContactSupportClick}
                        >
                          Contact Support
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>

          <div
            className={`${css.betCardTitle} d-flex align-items-center `}
            style={{ fontFamily: "Poppins", fontSize: "15px" }}
          >
            <span
              className="ml-auto"
              style={{ fontFamily: "Poppins", fontSize: "13px" }}
            >
              Prize
              <img className="mx-1" src={rupee_icon} alt="" width="21px" />
              {runnig.gameAmount + winamountFun(runnig.gameAmount)}
            </span>
          </div>
        </div>
        <div className="py-1 row">
          <div className="pr-3 text-center col-5">
            {/* {console.log("https://nightking.com/user.png")} */}
            <div className="pl-2">
              {/* {runnig.createdBy.avatar ? (
              <img
                src={
                  baseUrl + `${runnig.createdBy && runnig.createdBy.avatar}`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = { getRandomAvatar };
                }}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            ) : (
              <img
                src={getRandomAvatar()}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            )} */}
            </div>
            <div style={{ textAlign: "center" }}>
              <span className={css.betCard_playerName}>
                {runnig.createdBy.name}
              </span>
            </div>
          </div>
          <div className="pr-3 text-center col-2 cxy">
          <div class="verselogo" style={{textTransform: "uppercase" , fontWeight: 500 , fontSize: "12px", color: "black"}}>
                                 <div class="vslogo">v/s</div>
                            </div>
          </div>
          <div className="text-center col-5">
            <div className="pl-2">
              {/* {console.log(getRandomAvatar(),'getRandomAvatar')} */}
              {/* {console.log(runnig.acceptedBy)} */}

              {/* {runnig?.acceptedBy.avatar ? 
            (
              <img
                src={
                  baseUrl + `${runnig.acceptedBy && runnig.acceptedBy.avatar}`
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = getRandomAvatar();
                }}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            ) : (
              <img
                src={getRandomAvatar()}
                alt=""
                width="25px"
                height="25px"
                style={{
                  borderTopLeftRadius: "50%",
                  borderTopRightRadius: "50%",
                  borderBottomRightRadius: "50%",
                  borderBottomLeftRadius: "50%",
                }}
              />
            )} */}
            </div>
            <div style={{ textAlign: "center" }}>
              <span className={css.betCard_playerName}>
                {runnig.acceptedBy.name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
