import React from 'react'

const Terminategame = () => {
  return (
    <div><p>
        Game has been terminated
    </p>
    </div>
  )
}

export default Terminategame;