import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Rightcontainer from "../Components/Rightcontainer";
import Kyc from "./Kyc";
import axios from "axios";

import "../css/landing.css";
import { Link } from "react-router-dom";

const Support = () => {

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }


  const [setting, setSetting] = useState()

  useEffect(()=>{
    axios
    .get(baseUrl + `settings/data`)
    .then((res) => {
      console.log(res);
      setSetting(res.data.whatsapp)
     
    })
    .catch((e) => {
     
    });
  },[])

  return (
    <div>
      <div
        className="leftContainer "
        style={{ height: "100vh", paddingTop: "65px" }}
      >
        <div className="m-2 mt-3">
          <Kyc />
        </div>
        <div className="cxy flex-column ">
          <img
            src={process.env.PUBLIC_URL + "/Images/contact_us.webp"}
            width="320px"
            alt=""
          />
          <div className="w-100 p-4">
          <div className="  justify-content-center pb-3">
            <button
              type="button"
              className=" text-white  support-btn"
              style={{ backgroundColor: "#0B5ED7" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                fill="currentColor"
              >
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm5 4a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm4 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
              </svg>{" "}
              Live Chat
            </button>
          </div>
          <div className="justify-content-center pb-3">
            <button
              type="button"
              className=" text-white  support-btn"
              style={{
                background:
                  "linear-gradient(45deg, rgb(240, 148, 51) 0%, rgb(230, 104, 60) 25%, rgb(220, 39, 67) 50%, rgb(204, 35, 102) 75%, rgb(188, 24, 136) 100%)",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="1em"
                height="1em"
                fill="currentColor"
              >
                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
              </svg>{" "}
              Connect On Instagram
            </button>
          </div>
          <div className=" justify-content-center ">
          {
              setting && setting[0].active === true ? (<>
              <a className="cxy" href={`https://wa.me/+${setting[0].number}`}>
                <button type="button" className=" text-white support-btn mb-3">
                  <i className="fa-brands fa-whatsapp pe-2 "></i>Connect On
                  Whatsapp 1
                </button>
              </a>
              </>) :
               (null)
            }
            {
              setting && setting[1].active === true ? (<>
              <a className="cxy" href={`https://wa.me/+${setting[1].number}`}>
                <button type="button" className=" text-white support-btn mb-3">
                  <i className="fa-brands fa-whatsapp pe-2 "></i>Connect On
                  Whatsapp 2
                </button>
              </a>
              </>) :
               (null)
            }
            
          </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </div>
  );
};

export default Support;
