import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import "./Component-css/BottomNav.css";

const BottomNav = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  // Update active state based on location.pathname
  useEffect(() => {
    setActive(location.pathname === '/' || location.pathname === '/wallet' || location.pathname === '/refer' || location.pathname === '/contact-us' || location.pathname === '/profile');
  }, [location.pathname]);


  return (
    <div className="bottom-nav-bar">
      <Link to="/"  onClick={() =>  setActive(true)} className={`nav-items ${active && location.pathname === "/" ? " bg-white py-0 rounded-pill" : ""} `}>
        <div className='d-flex justify-content-center align-items-center '>
          {
            active && location.pathname === "/" ? (<> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6 mr-1">
              <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
            <span className=''>Home</span></>) : (<><span className='text-white'>Home</span></>)
          }
         
        </div>
      </Link>
    

      <Link to="/wallet"  onClick={() =>  setActive(true)} className={`nav-items ${active && location.pathname === "/wallet" ? "  bg-white py-0 rounded-pill" : ""} `}>
        <div className='d-flex justify-content-center align-items-center '>
          {
            active && location.pathname === "/wallet" ? (<> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3" />
            </svg>
            
            <span className=''>wallet</span>
            </>) : (<><span className='text-white'>wallet</span></>)
          }
         
        </div>
      </Link>


      <Link to="/refer"  onClick={() =>  setActive(true)} className={`nav-items ${active && location.pathname === "/refer" ? "  bg-white py-0 rounded-pill" : ""} `}>
        <div className='d-flex justify-content-center align-items-center '>
          {
            active && location.pathname === "/refer" ? (<> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
            </svg>            
            <span className=''>Refer</span>
            </>) : (<><span className='text-white'>Refer</span></>)
          }
         
        </div>
      </Link>

      <Link to="/contact-us"  onClick={() =>  setActive(true)} className={`nav-items ${active && location.pathname === "/contact-us" ? "  bg-white py-0 rounded-pill" : ""} `}>
        <div className='d-flex justify-content-center align-items-center '>
          {
            active && location.pathname === "/contact-us" ? (<>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
            </svg>
            
            <span className=''>Support</span></>) : (<><span className='text-white'>Support</span></>)
          }
         
          
        </div>
      </Link>


      <Link to="/profile"  onClick={() =>  setActive(true)} className={`nav-items ${active && location.pathname === "/profile" ? "  bg-white py-0 rounded-pill" : ""} `}>
        <div className='d-flex justify-content-center align-items-center '>
          {
            active && location.pathname === "/profile" ? (<>
           <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 mr-1">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>

          <span className=''>Profile</span></>) : (<><span className='text-white'>Profile</span></>)
          }
          
        </div>
      </Link>
    </div>
  );
};

export default BottomNav;