import React, { memo } from "react";
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";
import acceptSound from "./accept.mp3";
import playSound from "./play.mp3";
import loading_old from "./loading_old.gif";
import rupee_icon from "./global-rupeeIcon.png";
import userimage from "../Avtar/user.png";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = beckendLocalApiUrl;
} else {
  baseUrl = beckendLiveApiUrl;
}
const BetCard = React.memo(
  ({
    allgame,
    user,
    deleteChallenge,
    getPost,
    RejectGame,
    winamountFun,
    AcceptChallang,
    updateChallenge,
  }) => {
    // if(user == allgame.createdBy._id && allgame.status == "new"){
    //   setTimeout(() => {
    //     alert('bettle timeout');
    //     deleteChallenge(allgame._id);
    //   }, 60000);
    // }

    return (
      <div className={`${css.betCard} mt-2 p-1 `}
      style={{border:"1px solid #00209d"}}>
        <span
          className={`${css.betCardTitle} pl-2 d-flex align-items-center fs-6 ${css.betTitleDiv}`}
          style={{ fontFamily: "Poppins" }}
        >
          Challange From
          <span className="ml-1" style={{ color: "brown", fontFamily:"Poppins" }}>
            {allgame.createdBy.name}
          </span>
          {user == allgame.createdBy._id && allgame.status == "new" && (
            <button
              className={` m-1 mb-1 ml-auto btn-danger rounded-3`}
              onClick={() => deleteChallenge(allgame._id)}
            >
              <i className="fa-solid fa-trash rounded btn-danger"></i>
            </button>
          )}
          {user == allgame.createdBy._id && allgame.status == "requested" && (
            <div className="d-flex ml-auto align-items-center">
              <Link
                to={{
                  pathname: `/viewgame1/${allgame._id}`,
                  state: { prevPath: window.location.pathname },
                }}
                onClick={(e) => getPost(allgame._id)}
                style={{ bottom: "0", borderRadius:"3px" }}
              >
                <button
                  className={`bg-success position-relative mx-1  text-white btn-inverse-success`}
                  style={{fontFamily:"Poppins"}}
                >
                  Start
                </button>
              </Link>
              <button
                className={`text-white bg-danger position-relative mx-1 btn-outline-youtube`}
                onClick={() => RejectGame(allgame._id)}
                style={{ bottom: "0", borderRadius:"3px" }}
              >
                Reject
              </button>
            </div>
          )}
        </span>
        <div className={`d-flex justify-content-between  pl-4 pe-4 ${css.betBodyDiv}`} style={{alignItems:'center'}}>
          <div className=" pb-1">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "11px",
                fontWeight: "600",
                color:"#555555",
                letterSpacing:"0.5px",
              }}
            >
              Entry Fee
            </span>
            <div>
              <img src={rupee_icon} alt="" width="21px" />
              <span className={css.betCardAmount}>{allgame.gameAmount}</span>
            </div>
          </div>
          
          {user == allgame.createdBy._id && allgame.status == "new" && (
            <div className="text-center col-8 ml-auto mt-auto mb-auto">
              <div className="pl-2 text-center">
                <img
                  src={loading_old}
                  style={{ width: "25px", height: "25px" }}
                />
              </div>
              {/* <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>
                Finding Player!
              </span>
            </div> */}
            </div>
          )}
        
          <div>
          {user != allgame.createdBy._id && allgame.status == "new" && (
           <div className="text-center  ">
             <button 
             className="border-0 py-2"
             style={{backgroundColor:"#01AD79",color:"#fff",fontFamily:"Poppins",fontSize:"19px",fontWeight:"600",paddingInline:"22px",borderRadius:"8px"}}
              onClick={() => AcceptChallang(allgame._id)}
            >
              Play
            </button>
           </div>

          )}
          </div>
          {user !== allgame.createdBy._id && allgame.status == "requested" && (
            <div className="d-flex align-items-center text-center justify-content-between  ">
              <div
                className={`  ${css.cxy} position-relative mx-1 text-white p-1 px-2  `}
                style={{backgroundColor:"#1F1FFB", fontFamily:"Poppins", borderRadius:"3px"}}
              >
                Requested
              </div>
              <button
                className={`  text-white mx-1   border-0 p-1 px-2`}
                style={{backgroundColor:"red",fontFamily:"Poppins", borderRadius:"3px"}}
                onClick={() => RejectGame(allgame._id)}
              >
                Cancel
              </button>
            </div>
          )}

         {user == allgame.createdBy._id && allgame.status == "requested" && (
            <div className="d-flex ml-auto align-items-center mt-1" style={{marginRight:"110px"}}>
              
              <audio src={acceptSound} autoPlay></audio>
               
              <div className="text-center col">
                <div className="pl-2">
                  {allgame.acceptedBy.avatar ? (
                    <img
                      src={baseUrl + `${allgame.acceptedBy.avatar}`}
                      alt=""
                      width="40px"
                      height="40px"
                      style={{
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderBottomRightRadius: "50%",
                        borderBottomLeftRadius: "50%",
                        marginTop: "5px",
                      }}
                    />
                  ) : (
                    <img
                      src={userimage}
                      alt=""
                      width="40px"
                      height="40px"
                      style={{
                        borderTopLeftRadius: "50%",
                        borderTopRightRadius: "50%",
                        borderBottomRightRadius: "50%",
                        borderBottomLeftRadius: "50%",
                        marginTop: "5px",
                      }}
                    />
                  )}
                </div>
                <div >
                  <span  style={{ lineHeight: 1, fontFamily:"Poppins", fontSize
                :"13px", fontWeight:"600" }} >
                    {allgame.acceptedBy.name}
                  </span>
                </div>
              </div>
            </div>
          )}
           
          {user !== allgame.createdBy._id && (allgame.status == "running" || allgame.status == "pending" ||
              allgame.status == "conflict") && (
            <div className="d-flex align-items-center">
              <audio src={playSound} autoPlay></audio>
              <Link
                className={`  ${css.cxy}  text-white fs-6 fw-bold p-2 px-3 `}
                style={{backgroundColor:"#783CD6", borderRadius:"5px", marginRight:"70px"}}
                to={{
                  pathname: `/viewgame1/${allgame._id}`,
                  state: { prevPath: window.location.pathname },
                }}
                onClick={(e) => updateChallenge(allgame._id)}
              >
                Start
              </Link>
            </div>
          )}
       
          <div>
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "600",
                color:"#555555",
                letterSpacing:"0.5px",

              }}
            >
              Prize
            </span>
            <div>
              <img src={rupee_icon} alt="" width="21px" />
              <span className={css.betCardAmount}>
                {allgame.gameAmount + winamountFun(allgame.gameAmount)}
              </span>
            </div>
          </div>
          
          {/* {user == allgame.acceptedBy._id && allgame.status == 'running' && <button className={`${css.bgSecondary} ${css.playButton} ${css.cxy}`} >start</button>} */}

         
        
        </div>
      </div>
    );
  }
);

export default memo(BetCard);
